export const tabell = [
  {
    bruto: "5 600",
    taxCut: 0,
  },
  {
    bruto: "5 700",
    taxCut: 1,
  },
  {
    bruto: "5 800",
    taxCut: 30,
  },
  {
    bruto: "5 900",
    taxCut: 59,
  },
  {
    bruto: "6 000",
    taxCut: 88,
  },
  {
    bruto: "6 100",
    taxCut: 116,
  },
  {
    bruto: "6 200",
    taxCut: 145,
  },
  {
    bruto: "6 300",
    taxCut: 174,
  },
  {
    bruto: "6 400",
    taxCut: 203,
  },
  {
    bruto: "6 500",
    taxCut: 232,
  },
  {
    bruto: "6 600",
    taxCut: 261,
  },
  {
    bruto: "6 700",
    taxCut: 290,
  },
  {
    bruto: "6 800",
    taxCut: 318,
  },
  {
    bruto: "6 900",
    taxCut: 347,
  },
  {
    bruto: "7 000",
    taxCut: 376,
  },
  {
    bruto: "7 100",
    taxCut: 405,
  },
  {
    bruto: "7 200",
    taxCut: 434,
  },
  {
    bruto: "7 300",
    taxCut: 463,
  },
  {
    bruto: "7 400",
    taxCut: 492,
  },
  {
    bruto: "7 500",
    taxCut: 520,
  },
  {
    bruto: "7 600",
    taxCut: 549,
  },
  {
    bruto: "7 700",
    taxCut: 578,
  },
  {
    bruto: "7 800",
    taxCut: 607,
  },
  {
    bruto: "7 900",
    taxCut: 636,
  },
  {
    bruto: "8 000",
    taxCut: 665,
  },
  {
    bruto: "8 100",
    taxCut: 694,
  },
  {
    bruto: "8 200",
    taxCut: 722,
  },
  {
    bruto: "8 300",
    taxCut: 751,
  },
  {
    bruto: "8 400",
    taxCut: 771,
  },
  {
    bruto: "8 500",
    taxCut: 780,
  },
  {
    bruto: "8 600",
    taxCut: 789,
  },
  {
    bruto: "8 700",
    taxCut: 798,
  },
  {
    bruto: "8 800",
    taxCut: 807,
  },
  {
    bruto: "8 900",
    taxCut: 816,
  },
  {
    bruto: "9 000",
    taxCut: 825,
  },
  {
    bruto: "9 100",
    taxCut: 834,
  },
  {
    bruto: "9 200",
    taxCut: 844,
  },
  {
    bruto: "9 300",
    taxCut: 853,
  },
  {
    bruto: "9 400",
    taxCut: 862,
  },
  {
    bruto: "9 500",
    taxCut: 871,
  },
  {
    bruto: "9 600",
    taxCut: 880,
  },
  {
    bruto: "9 700",
    taxCut: 889,
  },
  {
    bruto: "9 800",
    taxCut: 898,
  },
  {
    bruto: "9 900",
    taxCut: 907,
  },
  {
    bruto: "10 000",
    taxCut: 916,
  },
  {
    bruto: "10 100",
    taxCut: 926,
  },
  {
    bruto: "10 200",
    taxCut: 935,
  },
  {
    bruto: "10 300",
    taxCut: 944,
  },
  {
    bruto: "10 400",
    taxCut: 953,
  },
  {
    bruto: "10 500",
    taxCut: 962,
  },
  {
    bruto: "10 600",
    taxCut: 971,
  },
  {
    bruto: "10 700",
    taxCut: 980,
  },
  {
    bruto: "10 800",
    taxCut: 989,
  },
  {
    bruto: "10 900",
    taxCut: 998,
  },
  {
    bruto: "11 000",
    taxCut: "1 010",
  },
  {
    bruto: "11 100",
    taxCut: "1 034",
  },
  {
    bruto: "11 200",
    taxCut: "1 059",
  },
  {
    bruto: "11 300",
    taxCut: "1 083",
  },
  {
    bruto: "11 400",
    taxCut: "1 107",
  },
  {
    bruto: "11 500",
    taxCut: "1 131",
  },
  {
    bruto: "11 600",
    taxCut: "1 155",
  },
  {
    bruto: "11 700",
    taxCut: "1 180",
  },
  {
    bruto: "11 800",
    taxCut: "1 204",
  },
  {
    bruto: "11 900",
    taxCut: "1 228",
  },
  {
    bruto: "12 000",
    taxCut: "1 252",
  },
  {
    bruto: "12 100",
    taxCut: "1 277",
  },
  {
    bruto: "12 200",
    taxCut: "1 301",
  },
  {
    bruto: "12 300",
    taxCut: "1 325",
  },
  {
    bruto: "12 400",
    taxCut: "1 349",
  },
  {
    bruto: "12 500",
    taxCut: "1 373",
  },
  {
    bruto: "12 600",
    taxCut: "1 398",
  },
  {
    bruto: "12 700",
    taxCut: "1 422",
  },
  {
    bruto: "12 800",
    taxCut: "1 446",
  },
  {
    bruto: "12 900",
    taxCut: "1 470",
  },
  {
    bruto: "13 000",
    taxCut: "1 495",
  },
  {
    bruto: "13 100",
    taxCut: "1 519",
  },
  {
    bruto: "13 200",
    taxCut: "1 543",
  },
  {
    bruto: "13 300",
    taxCut: "1 567",
  },
  {
    bruto: "13 400",
    taxCut: "1 592",
  },
  {
    bruto: "13 500",
    taxCut: "1 616",
  },
  {
    bruto: "13 600",
    taxCut: "1 640",
  },
  {
    bruto: "13 700",
    taxCut: "1 664",
  },
  {
    bruto: "13 800",
    taxCut: "1 688",
  },
  {
    bruto: "13 900",
    taxCut: "1 713",
  },
  {
    bruto: "14 000",
    taxCut: "1 737",
  },
  {
    bruto: "14 100",
    taxCut: "1 761",
  },
  {
    bruto: "14 200",
    taxCut: "1 786",
  },
  {
    bruto: "14 300",
    taxCut: "1 810",
  },
  {
    bruto: "14 400",
    taxCut: "1 834",
  },
  {
    bruto: "14 500",
    taxCut: "1 858",
  },
  {
    bruto: "14 600",
    taxCut: "1 882",
  },
  {
    bruto: "14 700",
    taxCut: "1 907",
  },
  {
    bruto: "14 800",
    taxCut: "1 931",
  },
  {
    bruto: "14 900",
    taxCut: "1 955",
  },
  {
    bruto: "15 000",
    taxCut: "1 979",
  },
  {
    bruto: "15 100",
    taxCut: "2 004",
  },
  {
    bruto: "15 200",
    taxCut: "2 028",
  },
  {
    bruto: "15 300",
    taxCut: "2 052",
  },
  {
    bruto: "15 400",
    taxCut: "2 076",
  },
  {
    bruto: "15 500",
    taxCut: "2 100",
  },
  {
    bruto: "15 600",
    taxCut: "2 125",
  },
  {
    bruto: "15 700",
    taxCut: "2 149",
  },
  {
    bruto: "15 800",
    taxCut: "2 173",
  },
  {
    bruto: "15 900",
    taxCut: "2 198",
  },
  {
    bruto: "16 000",
    taxCut: "2 222",
  },
  {
    bruto: "16 100",
    taxCut: "2 246",
  },
  {
    bruto: "16 200",
    taxCut: "2 270",
  },
  {
    bruto: "16 300",
    taxCut: "2 294",
  },
  {
    bruto: "16 400",
    taxCut: "2 320",
  },
  {
    bruto: "16 500",
    taxCut: "2 346",
  },
  {
    bruto: "16 600",
    taxCut: "2 373",
  },
  {
    bruto: "16 700",
    taxCut: "2 399",
  },
  {
    bruto: "16 800",
    taxCut: "2 425",
  },
  {
    bruto: "16 900",
    taxCut: "2 451",
  },
  {
    bruto: "17 000",
    taxCut: "2 477",
  },
  {
    bruto: "17 100",
    taxCut: "2 504",
  },
  {
    bruto: "17 200",
    taxCut: "2 530",
  },
  {
    bruto: "17 300",
    taxCut: "2 556",
  },
  {
    bruto: "17 400",
    taxCut: "2 582",
  },
  {
    bruto: "17 500",
    taxCut: "2 608",
  },
  {
    bruto: "17 600",
    taxCut: "2 635",
  },
  {
    bruto: "17 700",
    taxCut: "2 661",
  },
  {
    bruto: "17 800",
    taxCut: "2 687",
  },
  {
    bruto: "17 900",
    taxCut: "2 713",
  },
  {
    bruto: "18 000",
    taxCut: "2 739",
  },
  {
    bruto: "18 100",
    taxCut: "2 766",
  },
  {
    bruto: "18 200",
    taxCut: "2 792",
  },
  {
    bruto: "18 300",
    taxCut: "2 818",
  },
  {
    bruto: "18 400",
    taxCut: "2 844",
  },
  {
    bruto: "18 500",
    taxCut: "2 870",
  },
  {
    bruto: "18 600",
    taxCut: "2 897",
  },
  {
    bruto: "18 700",
    taxCut: "2 924",
  },
  {
    bruto: "18 800",
    taxCut: "2 961",
  },
  {
    bruto: "18 900",
    taxCut: "2 997",
  },
  {
    bruto: "19 000",
    taxCut: "3 034",
  },
  {
    bruto: "19 100",
    taxCut: "3 070",
  },
  {
    bruto: "19 200",
    taxCut: "3 107",
  },
  {
    bruto: "19 300",
    taxCut: "3 143",
  },
  {
    bruto: "19 400",
    taxCut: "3 180",
  },
  {
    bruto: "19 500",
    taxCut: "3 216",
  },
  {
    bruto: "19 600",
    taxCut: "3 253",
  },
  {
    bruto: "19 700",
    taxCut: "3 289",
  },
  {
    bruto: "19 800",
    taxCut: "3 326",
  },
  {
    bruto: "19 900",
    taxCut: "3 362",
  },
  {
    bruto: "20 000",
    taxCut: "3 398",
  },
  {
    bruto: "20 100",
    taxCut: "3 435",
  },
  {
    bruto: "20 200",
    taxCut: "3 471",
  },
  {
    bruto: "20 300",
    taxCut: "3 508",
  },
  {
    bruto: "20 400",
    taxCut: "3 544",
  },
  {
    bruto: "20 500",
    taxCut: "3 581",
  },
  {
    bruto: "20 600",
    taxCut: "3 617",
  },
  {
    bruto: "20 700",
    taxCut: "3 654",
  },
  {
    bruto: "20 800",
    taxCut: "3 690",
  },
  {
    bruto: "20 900",
    taxCut: "3 727",
  },
  {
    bruto: "21 000",
    taxCut: "3 763",
  },
  {
    bruto: "21 100",
    taxCut: "3 800",
  },
  {
    bruto: "21 200",
    taxCut: "3 836",
  },
  {
    bruto: "21 300",
    taxCut: "3 873",
  },
  {
    bruto: "21 400",
    taxCut: "3 909",
  },
  {
    bruto: "21 500",
    taxCut: "3 946",
  },
  {
    bruto: "21 600",
    taxCut: "3 982",
  },
  {
    bruto: "21 700",
    taxCut: "4 019",
  },
  {
    bruto: "21 800",
    taxCut: "4 055",
  },
  {
    bruto: "21 900",
    taxCut: "4 092",
  },
  {
    bruto: "22 000",
    taxCut: "4 128",
  },
  {
    bruto: "22 100",
    taxCut: "4 164",
  },
  {
    bruto: "22 200",
    taxCut: "4 201",
  },
  {
    bruto: "22 300",
    taxCut: "4 238",
  },
  {
    bruto: "22 400",
    taxCut: "4 274",
  },
  {
    bruto: "22 500",
    taxCut: "4 310",
  },
  {
    bruto: "22 600",
    taxCut: "4 347",
  },
  {
    bruto: "22 700",
    taxCut: "4 383",
  },
  {
    bruto: "22 800",
    taxCut: "4 420",
  },
  {
    bruto: "22 900",
    taxCut: "4 456",
  },
  {
    bruto: "23 000",
    taxCut: "4 493",
  },
  {
    bruto: "23 100",
    taxCut: "4 532",
  },
  {
    bruto: "23 200",
    taxCut: "4 571",
  },
  {
    bruto: "23 300",
    taxCut: "4 611",
  },
  {
    bruto: "23 400",
    taxCut: "4 650",
  },
  {
    bruto: "23 500",
    taxCut: "4 689",
  },
  {
    bruto: "23 600",
    taxCut: "4 728",
  },
  {
    bruto: "23 700",
    taxCut: "4 767",
  },
  {
    bruto: "23 800",
    taxCut: "4 806",
  },
  {
    bruto: "23 900",
    taxCut: "4 846",
  },
  {
    bruto: "24 000",
    taxCut: "4 884",
  },
  {
    bruto: "24 100",
    taxCut: "4 924",
  },
  {
    bruto: "24 200",
    taxCut: "4 963",
  },
  {
    bruto: "24 300",
    taxCut: "5 002",
  },
  {
    bruto: "24 400",
    taxCut: "5 041",
  },
  {
    bruto: "24 500",
    taxCut: "5 080",
  },
  {
    bruto: "24 600",
    taxCut: "5 119",
  },
  {
    bruto: "24 700",
    taxCut: "5 158",
  },
  {
    bruto: "24 800",
    taxCut: "5 198",
  },
  {
    bruto: "24 900",
    taxCut: "5 237",
  },
  {
    bruto: "25 000",
    taxCut: "5 276",
  },
  {
    bruto: "25 100",
    taxCut: "5 315",
  },
  {
    bruto: "25 200",
    taxCut: "5 354",
  },
  {
    bruto: "25 300",
    taxCut: "5 393",
  },
  {
    bruto: "25 400",
    taxCut: "5 432",
  },
  {
    bruto: "25 500",
    taxCut: "5 472",
  },
  {
    bruto: "25 600",
    taxCut: "5 511",
  },
  {
    bruto: "25 700",
    taxCut: "5 550",
  },
  {
    bruto: "25 800",
    taxCut: "5 589",
  },
  {
    bruto: "25 900",
    taxCut: "5 628",
  },
  {
    bruto: "26 000",
    taxCut: "5 667",
  },
  {
    bruto: "26 100",
    taxCut: "5 706",
  },
  {
    bruto: "26 200",
    taxCut: "5 745",
  },
  {
    bruto: "26 300",
    taxCut: "5 785",
  },
  {
    bruto: "26 400",
    taxCut: "5 824",
  },
  {
    bruto: "26 500",
    taxCut: "5 863",
  },
  {
    bruto: "26 600",
    taxCut: "5 902",
  },
  {
    bruto: "26 700",
    taxCut: "5 941",
  },
  {
    bruto: "26 800",
    taxCut: "5 980",
  },
  {
    bruto: "26 900",
    taxCut: "6 019",
  },
  {
    bruto: "27 000",
    taxCut: "6 058",
  },
  {
    bruto: "27 100",
    taxCut: "6 098",
  },
  {
    bruto: "27 200",
    taxCut: "6 137",
  },
  {
    bruto: "27 300",
    taxCut: "6 176",
  },
  {
    bruto: "27 400",
    taxCut: "6 215",
  },
  {
    bruto: "27 500",
    taxCut: "6 254",
  },
  {
    bruto: "27 600",
    taxCut: "6 293",
  },
  {
    bruto: "27 700",
    taxCut: "6 332",
  },
  {
    bruto: "27 800",
    taxCut: "6 372",
  },
  {
    bruto: "27 900",
    taxCut: "6 411",
  },
  {
    bruto: "28 000",
    taxCut: "6 450",
  },
  {
    bruto: "28 100",
    taxCut: "6 489",
  },
  {
    bruto: "28 200",
    taxCut: "6 528",
  },
  {
    bruto: "28 300",
    taxCut: "6 567",
  },
  {
    bruto: "28 400",
    taxCut: "6 606",
  },
  {
    bruto: "28 500",
    taxCut: "6 645",
  },
  {
    bruto: "28 600",
    taxCut: "6 685",
  },
  {
    bruto: "28 700",
    taxCut: "6 724",
  },
  {
    bruto: "28 800",
    taxCut: "6 763",
  },
  {
    bruto: "28 900",
    taxCut: "6 802",
  },
  {
    bruto: "29 000",
    taxCut: "6 841",
  },
  {
    bruto: "29 100",
    taxCut: "6 880",
  },
  {
    bruto: "29 200",
    taxCut: "6 919",
  },
  {
    bruto: "29 300",
    taxCut: "6 958",
  },
  {
    bruto: "29 400",
    taxCut: "6 998",
  },
  {
    bruto: "29 500",
    taxCut: "7 037",
  },
  {
    bruto: "29 600",
    taxCut: "7 076",
  },
  {
    bruto: "29 700",
    taxCut: "7 115",
  },
  {
    bruto: "29 800",
    taxCut: "7 154",
  },
  {
    bruto: "29 900",
    taxCut: "7 193",
  },
  {
    bruto: "30 000",
    taxCut: "7 232",
  },
  {
    bruto: "30 100",
    taxCut: "7 272",
  },
  {
    bruto: "30 200",
    taxCut: "7 311",
  },
  {
    bruto: "30 300",
    taxCut: "7 350",
  },
  {
    bruto: "30 400",
    taxCut: "7 389",
  },
  {
    bruto: "30 500",
    taxCut: "7 428",
  },
  {
    bruto: "30 600",
    taxCut: "7 467",
  },
  {
    bruto: "30 700",
    taxCut: "7 506",
  },
  {
    bruto: "30 800",
    taxCut: "7 545",
  },
  {
    bruto: "30 900",
    taxCut: "7 584",
  },
  {
    bruto: "31 000",
    taxCut: "7 624",
  },
  {
    bruto: "31 100",
    taxCut: "7 663",
  },
  {
    bruto: "31 200",
    taxCut: "7 702",
  },
  {
    bruto: "31 300",
    taxCut: "7 741",
  },
  {
    bruto: "31 400",
    taxCut: "7 780",
  },
  {
    bruto: "31 500",
    taxCut: "7 819",
  },
  {
    bruto: "31 600",
    taxCut: "7 858",
  },
  {
    bruto: "31 700",
    taxCut: "7 898",
  },
  {
    bruto: "31 800",
    taxCut: "7 937",
  },
  {
    bruto: "31 900",
    taxCut: "7 976",
  },
  {
    bruto: "32 000",
    taxCut: "8 015",
  },
  {
    bruto: "32 100",
    taxCut: "8 054",
  },
  {
    bruto: "32 200",
    taxCut: "8 093",
  },
  {
    bruto: "32 300",
    taxCut: "8 132",
  },
  {
    bruto: "32 400",
    taxCut: "8 171",
  },
  {
    bruto: "32 500",
    taxCut: "8 211",
  },
  {
    bruto: "32 600",
    taxCut: "8 250",
  },
  {
    bruto: "32 700",
    taxCut: "8 289",
  },
  {
    bruto: "32 800",
    taxCut: "8 328",
  },
  {
    bruto: "32 900",
    taxCut: "8 367",
  },
  {
    bruto: "33 000",
    taxCut: "8 406",
  },
  {
    bruto: "33 100",
    taxCut: "8 445",
  },
  {
    bruto: "33 200",
    taxCut: "8 484",
  },
  {
    bruto: "33 300",
    taxCut: "8 524",
  },
  {
    bruto: "33 400",
    taxCut: "8 563",
  },
  {
    bruto: "33 500",
    taxCut: "8 602",
  },
  {
    bruto: "33 600",
    taxCut: "8 641",
  },
  {
    bruto: "33 700",
    taxCut: "8 680",
  },
  {
    bruto: "33 800",
    taxCut: "8 719",
  },
  {
    bruto: "33 900",
    taxCut: "8 758",
  },
  {
    bruto: "34 000",
    taxCut: "8 798",
  },
  {
    bruto: "34 100",
    taxCut: "8 837",
  },
  {
    bruto: "34 200",
    taxCut: "8 876",
  },
  {
    bruto: "34 300",
    taxCut: "8 915",
  },
  {
    bruto: "34 400",
    taxCut: "8 954",
  },
  {
    bruto: "34 500",
    taxCut: "8 993",
  },
  {
    bruto: "34 600",
    taxCut: "9 032",
  },
  {
    bruto: "34 700",
    taxCut: "9 071",
  },
  {
    bruto: "34 800",
    taxCut: "9 111",
  },
  {
    bruto: "34 900",
    taxCut: "9 150",
  },
  {
    bruto: "35 000",
    taxCut: "9 189",
  },
  {
    bruto: "35 100",
    taxCut: "9 228",
  },
  {
    bruto: "35 200",
    taxCut: "9 267",
  },
  {
    bruto: "35 300",
    taxCut: "9 306",
  },
  {
    bruto: "35 400",
    taxCut: "9 346",
  },
  {
    bruto: "35 500",
    taxCut: "9 384",
  },
  {
    bruto: "35 600",
    taxCut: "9 424",
  },
  {
    bruto: "35 700",
    taxCut: "9 463",
  },
  {
    bruto: "35 800",
    taxCut: "9 502",
  },
  {
    bruto: "35 900",
    taxCut: "9 541",
  },
  {
    bruto: "36 000",
    taxCut: "9 580",
  },
  {
    bruto: "36 100",
    taxCut: "9 619",
  },
  {
    bruto: "36 200",
    taxCut: "9 658",
  },
  {
    bruto: "36 300",
    taxCut: "9 698",
  },
  {
    bruto: "36 400",
    taxCut: "9 737",
  },
  {
    bruto: "36 500",
    taxCut: "9 776",
  },
  {
    bruto: "36 600",
    taxCut: "9 815",
  },
  {
    bruto: "36 700",
    taxCut: "9 854",
  },
  {
    bruto: "36 800",
    taxCut: "9 893",
  },
  {
    bruto: "36 900",
    taxCut: "9 932",
  },
  {
    bruto: "37 000",
    taxCut: "9 972",
  },
  {
    bruto: "37 100",
    taxCut: "10 011",
  },
  {
    bruto: "37 200",
    taxCut: "10 050",
  },
  {
    bruto: "37 300",
    taxCut: "10 089",
  },
  {
    bruto: "37 400",
    taxCut: "10 128",
  },
  {
    bruto: "37 500",
    taxCut: "10 167",
  },
  {
    bruto: "37 600",
    taxCut: "10 206",
  },
  {
    bruto: "37 700",
    taxCut: "10 245",
  },
  {
    bruto: "37 800",
    taxCut: "10 285",
  },
  {
    bruto: "37 900",
    taxCut: "10 324",
  },
  {
    bruto: "38 000",
    taxCut: "10 363",
  },
  {
    bruto: "38 100",
    taxCut: "10 402",
  },
  {
    bruto: "38 200",
    taxCut: "10 441",
  },
  {
    bruto: "38 300",
    taxCut: "10 480",
  },
  {
    bruto: "38 400",
    taxCut: "10 519",
  },
  {
    bruto: "38 500",
    taxCut: "10 559",
  },
  {
    bruto: "38 600",
    taxCut: "10 598",
  },
  {
    bruto: "38 700",
    taxCut: "10 637",
  },
  {
    bruto: "38 800",
    taxCut: "10 676",
  },
  {
    bruto: "38 900",
    taxCut: "10 715",
  },
  {
    bruto: "39 000",
    taxCut: "10 754",
  },
  {
    bruto: "39 100",
    taxCut: "10 793",
  },
  {
    bruto: "39 200",
    taxCut: "10 832",
  },
  {
    bruto: "39 300",
    taxCut: "10 871",
  },
  {
    bruto: "39 400",
    taxCut: "10 911",
  },
  {
    bruto: "39 500",
    taxCut: "10 950",
  },
  {
    bruto: "39 600",
    taxCut: "10 989",
  },
  {
    bruto: "39 700",
    taxCut: "11 028",
  },
  {
    bruto: "39 800",
    taxCut: "11 067",
  },
  {
    bruto: "39 900",
    taxCut: "11 106",
  },
  {
    bruto: "40 000",
    taxCut: "11 145",
  },
  {
    bruto: "40 100",
    taxCut: "11 185",
  },
  {
    bruto: "40 200",
    taxCut: "11 224",
  },
  {
    bruto: "40 300",
    taxCut: "11 263",
  },
  {
    bruto: "40 400",
    taxCut: "11 302",
  },
  {
    bruto: "40 500",
    taxCut: "11 341",
  },
  {
    bruto: "40 600",
    taxCut: "11 380",
  },
  {
    bruto: "40 700",
    taxCut: "11 419",
  },
  {
    bruto: "40 800",
    taxCut: "11 458",
  },
  {
    bruto: "40 900",
    taxCut: "11 498",
  },
  {
    bruto: "41 000",
    taxCut: "11 537",
  },
  {
    bruto: "41 100",
    taxCut: "11 576",
  },
  {
    bruto: "41 200",
    taxCut: "11 615",
  },
  {
    bruto: "41 300",
    taxCut: "11 654",
  },
  {
    bruto: "41 400",
    taxCut: "11 693",
  },
  {
    bruto: "41 500",
    taxCut: "11 732",
  },
  {
    bruto: "41 600",
    taxCut: "11 772",
  },
  {
    bruto: "41 700",
    taxCut: "11 811",
  },
  {
    bruto: "41 800",
    taxCut: "11 850",
  },
  {
    bruto: "41 900",
    taxCut: "11 889",
  },
  {
    bruto: "42 000",
    taxCut: "11 928",
  },
  {
    bruto: "42 100",
    taxCut: "11 967",
  },
  {
    bruto: "42 200",
    taxCut: "12 006",
  },
  {
    bruto: "42 300",
    taxCut: "12 045",
  },
  {
    bruto: "42 400",
    taxCut: "12 084",
  },
  {
    bruto: "42 500",
    taxCut: "12 124",
  },
  {
    bruto: "42 600",
    taxCut: "12 163",
  },
  {
    bruto: "42 700",
    taxCut: "12 202",
  },
  {
    bruto: "42 800",
    taxCut: "12 241",
  },
  {
    bruto: "42 900",
    taxCut: "12 280",
  },
  {
    bruto: "43 000",
    taxCut: "12 319",
  },
  {
    bruto: "43 100",
    taxCut: "12 358",
  },
  {
    bruto: "43 200",
    taxCut: "12 398",
  },
  {
    bruto: "43 300",
    taxCut: "12 437",
  },
  {
    bruto: "43 400",
    taxCut: "12 476",
  },
  {
    bruto: "43 500",
    taxCut: "12 515",
  },
  {
    bruto: "43 600",
    taxCut: "12 554",
  },
  {
    bruto: "43 700",
    taxCut: "12 593",
  },
  {
    bruto: "43 800",
    taxCut: "12 632",
  },
  {
    bruto: "43 900",
    taxCut: "12 671",
  },
  {
    bruto: "44 000",
    taxCut: "12 711",
  },
  {
    bruto: "44 100",
    taxCut: "12 750",
  },
  {
    bruto: "44 200",
    taxCut: "12 789",
  },
  {
    bruto: "44 300",
    taxCut: "12 828",
  },
  {
    bruto: "44 400",
    taxCut: "12 867",
  },
  {
    bruto: "44 500",
    taxCut: "12 906",
  },
  {
    bruto: "44 600",
    taxCut: "12 945",
  },
  {
    bruto: "44 700",
    taxCut: "12 984",
  },
  {
    bruto: "44 800",
    taxCut: "13 024",
  },
  {
    bruto: "44 900",
    taxCut: "13 063",
  },
  {
    bruto: "45 000",
    taxCut: "13 102",
  },
  {
    bruto: "45 100",
    taxCut: "13 141",
  },
  {
    bruto: "45 200",
    taxCut: "13 180",
  },
  {
    bruto: "45 300",
    taxCut: "13 219",
  },
  {
    bruto: "45 400",
    taxCut: "13 258",
  },
  {
    bruto: "45 500",
    taxCut: "13 298",
  },
  {
    bruto: "45 600",
    taxCut: "13 337",
  },
  {
    bruto: "45 700",
    taxCut: "13 376",
  },
  {
    bruto: "45 800",
    taxCut: "13 415",
  },
  {
    bruto: "45 900",
    taxCut: "13 454",
  },
  {
    bruto: "46 000",
    taxCut: "13 493",
  },
  {
    bruto: "46 100",
    taxCut: "13 532",
  },
  {
    bruto: "46 200",
    taxCut: "13 572",
  },
  {
    bruto: "46 300",
    taxCut: "13 611",
  },
  {
    bruto: "46 400",
    taxCut: "13 650",
  },
  {
    bruto: "46 500",
    taxCut: "13 689",
  },
  {
    bruto: "46 600",
    taxCut: "13 728",
  },
  {
    bruto: "46 700",
    taxCut: "13 767",
  },
  {
    bruto: "46 800",
    taxCut: "13 806",
  },
  {
    bruto: "46 900",
    taxCut: "13 845",
  },
  {
    bruto: "47 000",
    taxCut: "13 884",
  },
  {
    bruto: "47 100",
    taxCut: "13 924",
  },
  {
    bruto: "47 200",
    taxCut: "13 963",
  },
  {
    bruto: "47 300",
    taxCut: "14 002",
  },
  {
    bruto: "47 400",
    taxCut: "14 041",
  },
  {
    bruto: "47 500",
    taxCut: "14 080",
  },
  {
    bruto: "47 600",
    taxCut: "14 119",
  },
  {
    bruto: "47 700",
    taxCut: "14 158",
  },
  {
    bruto: "47 800",
    taxCut: "14 198",
  },
  {
    bruto: "47 900",
    taxCut: "14 237",
  },
  {
    bruto: "48 000",
    taxCut: "14 276",
  },
  {
    bruto: "48 100",
    taxCut: "14 315",
  },
  {
    bruto: "48 200",
    taxCut: "14 354",
  },
  {
    bruto: "48 300",
    taxCut: "14 393",
  },
  {
    bruto: "48 400",
    taxCut: "14 432",
  },
  {
    bruto: "48 500",
    taxCut: "14 472",
  },
  {
    bruto: "48 600",
    taxCut: "14 511",
  },
  {
    bruto: "48 700",
    taxCut: "14 550",
  },
  {
    bruto: "48 800",
    taxCut: "14 589",
  },
  {
    bruto: "48 900",
    taxCut: "14 628",
  },
  {
    bruto: "49 000",
    taxCut: "14 667",
  },
  {
    bruto: "49 100",
    taxCut: "14 706",
  },
  {
    bruto: "49 200",
    taxCut: "14 745",
  },
  {
    bruto: "49 300",
    taxCut: "14 784",
  },
  {
    bruto: "49 400",
    taxCut: "14 824",
  },
  {
    bruto: "49 500",
    taxCut: "14 863",
  },
  {
    bruto: "49 600",
    taxCut: "14 902",
  },
  {
    bruto: "49 700",
    taxCut: "14 941",
  },
  {
    bruto: "49 800",
    taxCut: "14 980",
  },
  {
    bruto: "49 900",
    taxCut: "15 019",
  },
  {
    bruto: "50 000",
    taxCut: "15 058",
  },
  {
    bruto: "50 100",
    taxCut: "15 098",
  },
  {
    bruto: "50 200",
    taxCut: "15 137",
  },
  {
    bruto: "50 300",
    taxCut: "15 176",
  },
  {
    bruto: "50 400",
    taxCut: "15 215",
  },
  {
    bruto: "50 500",
    taxCut: "15 254",
  },
  {
    bruto: "50 600",
    taxCut: "15 293",
  },
  {
    bruto: "50 700",
    taxCut: "15 332",
  },
  {
    bruto: "50 800",
    taxCut: "15 372",
  },
  {
    bruto: "50 900",
    taxCut: "15 411",
  },
  {
    bruto: "51 000",
    taxCut: "15 450",
  },
  {
    bruto: "51 100",
    taxCut: "15 489",
  },
  {
    bruto: "51 200",
    taxCut: "15 528",
  },
  {
    bruto: "51 300",
    taxCut: "15 567",
  },
  {
    bruto: "51 400",
    taxCut: "15 606",
  },
  {
    bruto: "51 500",
    taxCut: "15 645",
  },
  {
    bruto: "51 600",
    taxCut: "15 685",
  },
  {
    bruto: "51 700",
    taxCut: "15 724",
  },
  {
    bruto: "51 800",
    taxCut: "15 763",
  },
  {
    bruto: "51 900",
    taxCut: "15 802",
  },
  {
    bruto: "52 000",
    taxCut: "15 841",
  },
  {
    bruto: "52 100",
    taxCut: "15 880",
  },
  {
    bruto: "52 200",
    taxCut: "15 919",
  },
  {
    bruto: "52 300",
    taxCut: "15 958",
  },
  {
    bruto: "52 400",
    taxCut: "15 998",
  },
  {
    bruto: "52 500",
    taxCut: "16 037",
  },
  {
    bruto: "52 600",
    taxCut: "16 076",
  },
  {
    bruto: "52 700",
    taxCut: "16 115",
  },
  {
    bruto: "52 800",
    taxCut: "16 154",
  },
  {
    bruto: "52 900",
    taxCut: "16 193",
  },
  {
    bruto: "53 000",
    taxCut: "16 232",
  },
  {
    bruto: "53 100",
    taxCut: "16 283",
  },
  {
    bruto: "53 200",
    taxCut: "16 333",
  },
  {
    bruto: "53 300",
    taxCut: "16 383",
  },
  {
    bruto: "53 400",
    taxCut: "16 433",
  },
  {
    bruto: "53 500",
    taxCut: "16 483",
  },
  {
    bruto: "53 600",
    taxCut: "16 533",
  },
  {
    bruto: "53 700",
    taxCut: "16 583",
  },
  {
    bruto: "53 800",
    taxCut: "16 633",
  },
  {
    bruto: "53 900",
    taxCut: "16 683",
  },
  {
    bruto: "54 000",
    taxCut: "16 734",
  },
  {
    bruto: "54 100",
    taxCut: "16 784",
  },
  {
    bruto: "54 200",
    taxCut: "16 834",
  },
  {
    bruto: "54 300",
    taxCut: "16 884",
  },
  {
    bruto: "54 400",
    taxCut: "16 934",
  },
  {
    bruto: "54 500",
    taxCut: "16 984",
  },
  {
    bruto: "54 600",
    taxCut: "17 034",
  },
  {
    bruto: "54 700",
    taxCut: "17 084",
  },
  {
    bruto: "54 800",
    taxCut: "17 134",
  },
  {
    bruto: "54 900",
    taxCut: "17 184",
  },
  {
    bruto: "55 000",
    taxCut: "17 234",
  },
  {
    bruto: "55 100",
    taxCut: "17 285",
  },
  {
    bruto: "55 200",
    taxCut: "17 335",
  },
  {
    bruto: "55 300",
    taxCut: "17 385",
  },
  {
    bruto: "55 400",
    taxCut: "17 435",
  },
  {
    bruto: "55 500",
    taxCut: "17 485",
  },
  {
    bruto: "55 600",
    taxCut: "17 535",
  },
  {
    bruto: "55 700",
    taxCut: "17 585",
  },
  {
    bruto: "55 800",
    taxCut: "17 635",
  },
  {
    bruto: "55 900",
    taxCut: "17 685",
  },
  {
    bruto: "56 000",
    taxCut: "17 735",
  },
  {
    bruto: "56 100",
    taxCut: "17 786",
  },
  {
    bruto: "56 200",
    taxCut: "17 836",
  },
  {
    bruto: "56 300",
    taxCut: "17 886",
  },
  {
    bruto: "56 400",
    taxCut: "17 936",
  },
  {
    bruto: "56 500",
    taxCut: "17 986",
  },
  {
    bruto: "56 600",
    taxCut: "18 036",
  },
  {
    bruto: "56 700",
    taxCut: "18 086",
  },
  {
    bruto: "56 800",
    taxCut: "18 136",
  },
  {
    bruto: "56 900",
    taxCut: "18 186",
  },
  {
    bruto: "57 000",
    taxCut: "18 236",
  },
  {
    bruto: "57 100",
    taxCut: "18 286",
  },
  {
    bruto: "57 200",
    taxCut: "18 337",
  },
  {
    bruto: "57 300",
    taxCut: "18 387",
  },
  {
    bruto: "57 400",
    taxCut: "18 437",
  },
  {
    bruto: "57 500",
    taxCut: "18 487",
  },
  {
    bruto: "57 600",
    taxCut: "18 537",
  },
  {
    bruto: "57 700",
    taxCut: "18 587",
  },
  {
    bruto: "57 800",
    taxCut: "18 637",
  },
  {
    bruto: "57 900",
    taxCut: "18 687",
  },
  {
    bruto: "58 000",
    taxCut: "18 737",
  },
  {
    bruto: "58 100",
    taxCut: "18 787",
  },
  {
    bruto: "58 200",
    taxCut: "18 837",
  },
  {
    bruto: "58 300",
    taxCut: "18 888",
  },
  {
    bruto: "58 400",
    taxCut: "18 938",
  },
  {
    bruto: "58 500",
    taxCut: "18 988",
  },
  {
    bruto: "58 600",
    taxCut: "19 038",
  },
  {
    bruto: "58 700",
    taxCut: "19 088",
  },
  {
    bruto: "58 800",
    taxCut: "19 138",
  },
  {
    bruto: "58 900",
    taxCut: "19 188",
  },
  {
    bruto: "59 000",
    taxCut: "19 238",
  },
  {
    bruto: "59 100",
    taxCut: "19 288",
  },
  {
    bruto: "59 200",
    taxCut: "19 338",
  },
  {
    bruto: "59 300",
    taxCut: "19 389",
  },
  {
    bruto: "59 400",
    taxCut: "19 439",
  },
  {
    bruto: "59 500",
    taxCut: "19 489",
  },
  {
    bruto: "59 600",
    taxCut: "19 539",
  },
  {
    bruto: "59 700",
    taxCut: "19 589",
  },
  {
    bruto: "59 800",
    taxCut: "19 639",
  },
  {
    bruto: "59 900",
    taxCut: "19 689",
  },
  {
    bruto: "60 000",
    taxCut: "19 739",
  },
  {
    bruto: "60 100",
    taxCut: "19 789",
  },
  {
    bruto: "60 200",
    taxCut: "19 839",
  },
  {
    bruto: "60 300",
    taxCut: "19 890",
  },
  {
    bruto: "60 400",
    taxCut: "19 940",
  },
  {
    bruto: "60 500",
    taxCut: "19 990",
  },
  {
    bruto: "60 600",
    taxCut: "20 040",
  },
  {
    bruto: "60 700",
    taxCut: "20 090",
  },
  {
    bruto: "60 800",
    taxCut: "20 140",
  },
  {
    bruto: "60 900",
    taxCut: "20 190",
  },
  {
    bruto: "61 000",
    taxCut: "20 240",
  },
  {
    bruto: "61 100",
    taxCut: "20 290",
  },
  {
    bruto: "61 200",
    taxCut: "20 340",
  },
  {
    bruto: "61 300",
    taxCut: "20 390",
  },
  {
    bruto: "61 400",
    taxCut: "20 441",
  },
  {
    bruto: "61 500",
    taxCut: "20 491",
  },
  {
    bruto: "61 600",
    taxCut: "20 541",
  },
  {
    bruto: "61 700",
    taxCut: "20 591",
  },
  {
    bruto: "61 800",
    taxCut: "20 641",
  },
  {
    bruto: "61 900",
    taxCut: "20 691",
  },
  {
    bruto: "62 000",
    taxCut: "20 741",
  },
  {
    bruto: "62 100",
    taxCut: "20 791",
  },
  {
    bruto: "62 200",
    taxCut: "20 841",
  },
  {
    bruto: "62 300",
    taxCut: "20 891",
  },
  {
    bruto: "62 400",
    taxCut: "20 942",
  },
  {
    bruto: "62 500",
    taxCut: "20 992",
  },
  {
    bruto: "62 600",
    taxCut: "21 042",
  },
  {
    bruto: "62 700",
    taxCut: "21 092",
  },
  {
    bruto: "62 800",
    taxCut: "21 142",
  },
  {
    bruto: "62 900",
    taxCut: "21 192",
  },
  {
    bruto: "63 000",
    taxCut: "21 242",
  },
  {
    bruto: "63 100",
    taxCut: "21 292",
  },
  {
    bruto: "63 200",
    taxCut: "21 342",
  },
  {
    bruto: "63 300",
    taxCut: "21 392",
  },
  {
    bruto: "63 400",
    taxCut: "21 442",
  },
  {
    bruto: "63 500",
    taxCut: "21 493",
  },
  {
    bruto: "63 600",
    taxCut: "21 543",
  },
  {
    bruto: "63 700",
    taxCut: "21 593",
  },
  {
    bruto: "63 800",
    taxCut: "21 643",
  },
  {
    bruto: "63 900",
    taxCut: "21 693",
  },
  {
    bruto: "64 000",
    taxCut: "21 743",
  },
  {
    bruto: "64 100",
    taxCut: "21 793",
  },
  {
    bruto: "64 200",
    taxCut: "21 843",
  },
  {
    bruto: "64 300",
    taxCut: "21 893",
  },
  {
    bruto: "64 400",
    taxCut: "21 943",
  },
  {
    bruto: "64 500",
    taxCut: "21 994",
  },
  {
    bruto: "64 600",
    taxCut: "22 044",
  },
  {
    bruto: "64 700",
    taxCut: "22 094",
  },
  {
    bruto: "64 800",
    taxCut: "22 144",
  },
  {
    bruto: "64 900",
    taxCut: "22 194",
  },
  {
    bruto: "65 000",
    taxCut: "22 244",
  },
  {
    bruto: "65 100",
    taxCut: "22 294",
  },
  {
    bruto: "65 200",
    taxCut: "22 344",
  },
  {
    bruto: "65 300",
    taxCut: "22 394",
  },
  {
    bruto: "65 400",
    taxCut: "22 444",
  },
  {
    bruto: "65 500",
    taxCut: "22 494",
  },
  {
    bruto: "65 600",
    taxCut: "22 545",
  },
  {
    bruto: "65 700",
    taxCut: "22 595",
  },
  {
    bruto: "65 800",
    taxCut: "22 645",
  },
  {
    bruto: "65 900",
    taxCut: "22 695",
  },
  {
    bruto: "66 000",
    taxCut: "22 745",
  },
  {
    bruto: "66 100",
    taxCut: "22 795",
  },
  {
    bruto: "66 200",
    taxCut: "22 845",
  },
  {
    bruto: "66 300",
    taxCut: "22 895",
  },
  {
    bruto: "66 400",
    taxCut: "22 945",
  },
  {
    bruto: "66 500",
    taxCut: "22 995",
  },
  {
    bruto: "66 600",
    taxCut: "23 046",
  },
  {
    bruto: "66 700",
    taxCut: "23 096",
  },
  {
    bruto: "66 800",
    taxCut: "23 146",
  },
  {
    bruto: "66 900",
    taxCut: "23 196",
  },
  {
    bruto: "67 000",
    taxCut: "23 246",
  },
  {
    bruto: "67 100",
    taxCut: "23 296",
  },
  {
    bruto: "67 200",
    taxCut: "23 346",
  },
  {
    bruto: "67 300",
    taxCut: "23 396",
  },
  {
    bruto: "67 400",
    taxCut: "23 446",
  },
  {
    bruto: "67 500",
    taxCut: "23 496",
  },
  {
    bruto: "67 600",
    taxCut: "23 547",
  },
  {
    bruto: "67 700",
    taxCut: "23 597",
  },
  {
    bruto: "67 800",
    taxCut: "23 647",
  },
  {
    bruto: "67 900",
    taxCut: "23 697",
  },
  {
    bruto: "68 000",
    taxCut: "23 747",
  },
  {
    bruto: "68 100",
    taxCut: "23 797",
  },
  {
    bruto: "68 200",
    taxCut: "23 847",
  },
  {
    bruto: "68 300",
    taxCut: "23 897",
  },
  {
    bruto: "68 400",
    taxCut: "23 947",
  },
  {
    bruto: "68 500",
    taxCut: "23 997",
  },
  {
    bruto: "68 600",
    taxCut: "24 047",
  },
  {
    bruto: "68 700",
    taxCut: "24 098",
  },
  {
    bruto: "68 800",
    taxCut: "24 148",
  },
  {
    bruto: "68 900",
    taxCut: "24 198",
  },
  {
    bruto: "69 000",
    taxCut: "24 248",
  },
  {
    bruto: "69 100",
    taxCut: "24 298",
  },
  {
    bruto: "69 200",
    taxCut: "24 348",
  },
  {
    bruto: "69 300",
    taxCut: "24 398",
  },
  {
    bruto: "69 400",
    taxCut: "24 448",
  },
  {
    bruto: "69 500",
    taxCut: "24 498",
  },
  {
    bruto: "69 600",
    taxCut: "24 548",
  },
  {
    bruto: "69 700",
    taxCut: "24 598",
  },
  {
    bruto: "69 800",
    taxCut: "24 649",
  },
  {
    bruto: "69 900",
    taxCut: "24 699",
  },
  {
    bruto: "70 000",
    taxCut: "24 749",
  },
  {
    bruto: "70 100",
    taxCut: "24 799",
  },
  {
    bruto: "70 200",
    taxCut: "24 849",
  },
  {
    bruto: "70 300",
    taxCut: "24 899",
  },
  {
    bruto: "70 400",
    taxCut: "24 949",
  },
  {
    bruto: "70 500",
    taxCut: "24 999",
  },
  {
    bruto: "70 600",
    taxCut: "25 049",
  },
  {
    bruto: "70 700",
    taxCut: "25 100",
  },
  {
    bruto: "70 800",
    taxCut: "25 150",
  },
  {
    bruto: "70 900",
    taxCut: "25 200",
  },
  {
    bruto: "71 000",
    taxCut: "25 250",
  },
  {
    bruto: "71 100",
    taxCut: "25 300",
  },
  {
    bruto: "71 200",
    taxCut: "25 350",
  },
  {
    bruto: "71 300",
    taxCut: "25 400",
  },
  {
    bruto: "71 400",
    taxCut: "25 450",
  },
  {
    bruto: "71 500",
    taxCut: "25 500",
  },
  {
    bruto: "71 600",
    taxCut: "25 550",
  },
  {
    bruto: "71 700",
    taxCut: "25 600",
  },
  {
    bruto: "71 800",
    taxCut: "25 651",
  },
  {
    bruto: "71 900",
    taxCut: "25 701",
  },
  {
    bruto: "72 000",
    taxCut: "25 751",
  },
  {
    bruto: "72 100",
    taxCut: "25 801",
  },
  {
    bruto: "72 200",
    taxCut: "25 851",
  },
  {
    bruto: "72 300",
    taxCut: "25 901",
  },
  {
    bruto: "72 400",
    taxCut: "25 951",
  },
  {
    bruto: "72 500",
    taxCut: "26 001",
  },
  {
    bruto: "72 600",
    taxCut: "26 051",
  },
  {
    bruto: "72 700",
    taxCut: "26 101",
  },
  {
    bruto: "72 800",
    taxCut: "26 152",
  },
  {
    bruto: "72 900",
    taxCut: "26 202",
  },
  {
    bruto: "73 000",
    taxCut: "26 252",
  },
  {
    bruto: "73 100",
    taxCut: "26 302",
  },
  {
    bruto: "73 200",
    taxCut: "26 352",
  },
  {
    bruto: "73 300",
    taxCut: "26 402",
  },
  {
    bruto: "73 400",
    taxCut: "26 452",
  },
  {
    bruto: "73 500",
    taxCut: "26 502",
  },
  {
    bruto: "73 600",
    taxCut: "26 552",
  },
  {
    bruto: "73 700",
    taxCut: "26 602",
  },
  {
    bruto: "73 800",
    taxCut: "26 652",
  },
  {
    bruto: "73 900",
    taxCut: "26 703",
  },
  {
    bruto: "74 000",
    taxCut: "26 753",
  },
  {
    bruto: "74 100",
    taxCut: "26 803",
  },
  {
    bruto: "74 200",
    taxCut: "26 853",
  },
  {
    bruto: "74 300",
    taxCut: "26 903",
  },
  {
    bruto: "74 400",
    taxCut: "26 953",
  },
  {
    bruto: "74 500",
    taxCut: "27 003",
  },
  {
    bruto: "74 600",
    taxCut: "27 053",
  },
  {
    bruto: "74 700",
    taxCut: "27 103",
  },
  {
    bruto: "74 800",
    taxCut: "27 153",
  },
  {
    bruto: "74 900",
    taxCut: "27 204",
  },
  {
    bruto: "75 000",
    taxCut: "27 254",
  },
  {
    bruto: "75 100",
    taxCut: "27 304",
  },
  {
    bruto: "75 200",
    taxCut: "27 354",
  },
  {
    bruto: "75 300",
    taxCut: "27 404",
  },
  {
    bruto: "75 400",
    taxCut: "27 454",
  },
  {
    bruto: "75 500",
    taxCut: "27 504",
  },
  {
    bruto: "75 600",
    taxCut: "27 554",
  },
  {
    bruto: "75 700",
    taxCut: "27 604",
  },
  {
    bruto: "75 800",
    taxCut: "27 654",
  },
  {
    bruto: "75 900",
    taxCut: "27 705",
  },
  {
    bruto: "76 000",
    taxCut: "27 755",
  },
  {
    bruto: "76 100",
    taxCut: "27 805",
  },
  {
    bruto: "76 200",
    taxCut: "27 855",
  },
  {
    bruto: "76 300",
    taxCut: "27 905",
  },
  {
    bruto: "76 400",
    taxCut: "27 955",
  },
  {
    bruto: "76 500",
    taxCut: "28 008",
  },
  {
    bruto: "76 600",
    taxCut: "28 061",
  },
  {
    bruto: "76 700",
    taxCut: "28 115",
  },
  {
    bruto: "76 800",
    taxCut: "28 168",
  },
  {
    bruto: "76 900",
    taxCut: "28 222",
  },
  {
    bruto: "77 000",
    taxCut: "28 276",
  },
  {
    bruto: "77 100",
    taxCut: "28 329",
  },
  {
    bruto: "77 200",
    taxCut: "28 383",
  },
  {
    bruto: "77 300",
    taxCut: "28 436",
  },
  {
    bruto: "77 400",
    taxCut: "28 490",
  },
  {
    bruto: "77 500",
    taxCut: "28 544",
  },
  {
    bruto: "77 600",
    taxCut: "28 597",
  },
  {
    bruto: "77 700",
    taxCut: "28 651",
  },
  {
    bruto: "77 800",
    taxCut: "28 704",
  },
  {
    bruto: "77 900",
    taxCut: "28 758",
  },
  {
    bruto: "78 000",
    taxCut: "28 811",
  },
  {
    bruto: "78 100",
    taxCut: "28 865",
  },
  {
    bruto: "78 200",
    taxCut: "28 918",
  },
  {
    bruto: "78 300",
    taxCut: "28 972",
  },
  {
    bruto: "78 400",
    taxCut: "29 026",
  },
  {
    bruto: "78 500",
    taxCut: "29 079",
  },
  {
    bruto: "78 600",
    taxCut: "29 133",
  },
  {
    bruto: "78 700",
    taxCut: "29 186",
  },
  {
    bruto: "78 800",
    taxCut: "29 240",
  },
  {
    bruto: "78 900",
    taxCut: "29 293",
  },
  {
    bruto: "79 000",
    taxCut: "29 347",
  },
  {
    bruto: "79 100",
    taxCut: "29 400",
  },
  {
    bruto: "79 200",
    taxCut: "29 454",
  },
  {
    bruto: "79 300",
    taxCut: "29 507",
  },
  {
    bruto: "79 400",
    taxCut: "29 561",
  },
  {
    bruto: "79 500",
    taxCut: "29 615",
  },
  {
    bruto: "79 600",
    taxCut: "29 668",
  },
  {
    bruto: "79 700",
    taxCut: "29 722",
  },
  {
    bruto: "79 800",
    taxCut: "29 775",
  },
  {
    bruto: "79 900",
    taxCut: "29 829",
  },
  {
    bruto: "80 000",
    taxCut: "29 882",
  },
  {
    bruto: "80 100",
    taxCut: "29 936",
  },
  {
    bruto: "80 200",
    taxCut: "29 990",
  },
  {
    bruto: "80 300",
    taxCut: "30 043",
  },
  {
    bruto: "80 400",
    taxCut: "30 097",
  },
  {
    bruto: "80 500",
    taxCut: "30 150",
  },
  {
    bruto: "80 600",
    taxCut: "30 204",
  },
  {
    bruto: "80 700",
    taxCut: "30 257",
  },
  {
    bruto: "80 800",
    taxCut: "30 311",
  },
  {
    bruto: "80 900",
    taxCut: "30 364",
  },
  {
    bruto: "81 000",
    taxCut: "30 418",
  },
  {
    bruto: "81 100",
    taxCut: "30 472",
  },
  {
    bruto: "81 200",
    taxCut: "30 525",
  },
  {
    bruto: "81 300",
    taxCut: "30 579",
  },
  {
    bruto: "81 400",
    taxCut: "30 632",
  },
  {
    bruto: "81 500",
    taxCut: "30 686",
  },
  {
    bruto: "81 600",
    taxCut: "30 739",
  },
  {
    bruto: "81 700",
    taxCut: "30 793",
  },
  {
    bruto: "81 800",
    taxCut: "30 847",
  },
  {
    bruto: "81 900",
    taxCut: "30 900",
  },
  {
    bruto: "82 000",
    taxCut: "30 954",
  },
  {
    bruto: "82 100",
    taxCut: "31 007",
  },
  {
    bruto: "82 200",
    taxCut: "31 061",
  },
  {
    bruto: "82 300",
    taxCut: "31 114",
  },
  {
    bruto: "82 400",
    taxCut: "31 168",
  },
  {
    bruto: "82 500",
    taxCut: "31 221",
  },
  {
    bruto: "82 600",
    taxCut: "31 275",
  },
  {
    bruto: "82 700",
    taxCut: "31 328",
  },
  {
    bruto: "82 800",
    taxCut: "31 382",
  },
  {
    bruto: "82 900",
    taxCut: "31 436",
  },
  {
    bruto: "83 000",
    taxCut: "31 489",
  },
  {
    bruto: "83 100",
    taxCut: "31 543",
  },
  {
    bruto: "83 200",
    taxCut: "31 596",
  },
  {
    bruto: "83 300",
    taxCut: "31 650",
  },
  {
    bruto: "83 400",
    taxCut: "31 703",
  },
  {
    bruto: "83 500",
    taxCut: "31 757",
  },
  {
    bruto: "83 600",
    taxCut: "31 811",
  },
  {
    bruto: "83 700",
    taxCut: "31 864",
  },
  {
    bruto: "83 800",
    taxCut: "31 918",
  },
  {
    bruto: "83 900",
    taxCut: "31 971",
  },
  {
    bruto: "84 000",
    taxCut: "32 025",
  },
  {
    bruto: "84 100",
    taxCut: "32 078",
  },
  {
    bruto: "84 200",
    taxCut: "32 132",
  },
  {
    bruto: "84 300",
    taxCut: "32 185",
  },
  {
    bruto: "84 400",
    taxCut: "32 239",
  },
  {
    bruto: "84 500",
    taxCut: "32 292",
  },
  {
    bruto: "84 600",
    taxCut: "32 346",
  },
  {
    bruto: "84 700",
    taxCut: "32 400",
  },
  {
    bruto: "84 800",
    taxCut: "32 453",
  },
  {
    bruto: "84 900",
    taxCut: "32 507",
  },
  {
    bruto: "85 000",
    taxCut: "32 560",
  },
  {
    bruto: "85 100",
    taxCut: "32 614",
  },
  {
    bruto: "85 200",
    taxCut: "32 667",
  },
  {
    bruto: "85 300",
    taxCut: "32 721",
  },
  {
    bruto: "85 400",
    taxCut: "32 775",
  },
  {
    bruto: "85 500",
    taxCut: "32 828",
  },
  {
    bruto: "85 600",
    taxCut: "32 882",
  },
  {
    bruto: "85 700",
    taxCut: "32 935",
  },
  {
    bruto: "85 800",
    taxCut: "32 989",
  },
  {
    bruto: "85 900",
    taxCut: "33 042",
  },
  {
    bruto: "86 000",
    taxCut: "33 096",
  },
  {
    bruto: "86 100",
    taxCut: "33 150",
  },
  {
    bruto: "86 200",
    taxCut: "33 203",
  },
  {
    bruto: "86 300",
    taxCut: "33 257",
  },
  {
    bruto: "86 400",
    taxCut: "33 310",
  },
  {
    bruto: "86 500",
    taxCut: "33 364",
  },
  {
    bruto: "86 600",
    taxCut: "33 417",
  },
  {
    bruto: "86 700",
    taxCut: "33 471",
  },
  {
    bruto: "86 800",
    taxCut: "33 524",
  },
  {
    bruto: "86 900",
    taxCut: "33 578",
  },
  {
    bruto: "87 000",
    taxCut: "33 632",
  },
  {
    bruto: "87 100",
    taxCut: "33 685",
  },
  {
    bruto: "87 200",
    taxCut: "33 739",
  },
  {
    bruto: "87 300",
    taxCut: "33 792",
  },
  {
    bruto: "87 400",
    taxCut: "33 846",
  },
  {
    bruto: "87 500",
    taxCut: "33 899",
  },
  {
    bruto: "87 600",
    taxCut: "33 953",
  },
  {
    bruto: "87 700",
    taxCut: "34 006",
  },
  {
    bruto: "87 800",
    taxCut: "34 060",
  },
  {
    bruto: "87 900",
    taxCut: "34 114",
  },
  {
    bruto: "88 000",
    taxCut: "34 167",
  },
  {
    bruto: "88 100",
    taxCut: "34 221",
  },
  {
    bruto: "88 200",
    taxCut: "34 274",
  },
  {
    bruto: "88 300",
    taxCut: "34 328",
  },
  {
    bruto: "88 400",
    taxCut: "34 381",
  },
  {
    bruto: "88 500",
    taxCut: "34 435",
  },
  {
    bruto: "88 600",
    taxCut: "34 488",
  },
  {
    bruto: "88 700",
    taxCut: "34 542",
  },
  {
    bruto: "88 800",
    taxCut: "34 596",
  },
  {
    bruto: "88 900",
    taxCut: "34 649",
  },
  {
    bruto: "89 000",
    taxCut: "34 703",
  },
  {
    bruto: "89 100",
    taxCut: "34 756",
  },
  {
    bruto: "89 200",
    taxCut: "34 810",
  },
  {
    bruto: "89 300",
    taxCut: "34 863",
  },
  {
    bruto: "89 400",
    taxCut: "34 917",
  },
  {
    bruto: "89 500",
    taxCut: "34 970",
  },
  {
    bruto: "89 600",
    taxCut: "35 024",
  },
  {
    bruto: "89 700",
    taxCut: "35 078",
  },
  {
    bruto: "89 800",
    taxCut: "35 131",
  },
  {
    bruto: "89 900",
    taxCut: "35 185",
  },
  {
    bruto: "90 000",
    taxCut: "35 238",
  },
  {
    bruto: "90 100",
    taxCut: "35 292",
  },
  {
    bruto: "90 200",
    taxCut: "35 345",
  },
  {
    bruto: "90 300",
    taxCut: "35 399",
  },
  {
    bruto: "90 400",
    taxCut: "35 452",
  },
  {
    bruto: "90 500",
    taxCut: "35 506",
  },
  {
    bruto: "90 600",
    taxCut: "35 560",
  },
  {
    bruto: "90 700",
    taxCut: "35 613",
  },
  {
    bruto: "90 800",
    taxCut: "35 667",
  },
  {
    bruto: "90 900",
    taxCut: "35 720",
  },
  {
    bruto: "91 000",
    taxCut: "35 774",
  },
  {
    bruto: "91 100",
    taxCut: "35 827",
  },
  {
    bruto: "91 200",
    taxCut: "35 881",
  },
  {
    bruto: "91 300",
    taxCut: "35 935",
  },
  {
    bruto: "91 400",
    taxCut: "35 988",
  },
  {
    bruto: "91 500",
    taxCut: "36 042",
  },
  {
    bruto: "91 600",
    taxCut: "36 095",
  },
  {
    bruto: "91 700",
    taxCut: "36 149",
  },
  {
    bruto: "91 800",
    taxCut: "36 202",
  },
  {
    bruto: "91 900",
    taxCut: "36 256",
  },
  {
    bruto: "92 000",
    taxCut: "36 310",
  },
  {
    bruto: "92 100",
    taxCut: "36 363",
  },
  {
    bruto: "92 200",
    taxCut: "36 417",
  },
  {
    bruto: "92 300",
    taxCut: "36 470",
  },
  {
    bruto: "92 400",
    taxCut: "36 524",
  },
  {
    bruto: "92 500",
    taxCut: "36 577",
  },
  {
    bruto: "92 600",
    taxCut: "36 631",
  },
  {
    bruto: "92 700",
    taxCut: "36 684",
  },
  {
    bruto: "92 800",
    taxCut: "36 738",
  },
  {
    bruto: "92 900",
    taxCut: "36 792",
  },
  {
    bruto: "93 000",
    taxCut: "36 845",
  },
  {
    bruto: "93 100",
    taxCut: "36 899",
  },
  {
    bruto: "93 200",
    taxCut: "36 952",
  },
  {
    bruto: "93 300",
    taxCut: "37 006",
  },
  {
    bruto: "93 400",
    taxCut: "37 059",
  },
  {
    bruto: "93 500",
    taxCut: "37 113",
  },
  {
    bruto: "93 600",
    taxCut: "37 166",
  },
  {
    bruto: "93 700",
    taxCut: "37 220",
  },
  {
    bruto: "93 800",
    taxCut: "37 274",
  },
  {
    bruto: "93 900",
    taxCut: "37 327",
  },
  {
    bruto: "94 000",
    taxCut: "37 381",
  },
  {
    bruto: "94 100",
    taxCut: "37 434",
  },
  {
    bruto: "94 200",
    taxCut: "37 488",
  },
  {
    bruto: "94 300",
    taxCut: "37 541",
  },
  {
    bruto: "94 400",
    taxCut: "37 595",
  },
  {
    bruto: "94 500",
    taxCut: "37 648",
  },
  {
    bruto: "94 600",
    taxCut: "37 702",
  },
  {
    bruto: "94 700",
    taxCut: "37 756",
  },
  {
    bruto: "94 800",
    taxCut: "37 809",
  },
  {
    bruto: "94 900",
    taxCut: "37 863",
  },
  {
    bruto: "95 000",
    taxCut: "37 916",
  },
  {
    bruto: "95 100",
    taxCut: "37 970",
  },
  {
    bruto: "95 200",
    taxCut: "38 023",
  },
  {
    bruto: "95 300",
    taxCut: "38 077",
  },
  {
    bruto: "95 400",
    taxCut: "38 130",
  },
  {
    bruto: "95 500",
    taxCut: "38 184",
  },
  {
    bruto: "95 600",
    taxCut: "38 238",
  },
  {
    bruto: "95 700",
    taxCut: "38 291",
  },
  {
    bruto: "95 800",
    taxCut: "38 345",
  },
  {
    bruto: "95 900",
    taxCut: "38 398",
  },
  {
    bruto: "96 000",
    taxCut: "38 452",
  },
  {
    bruto: "96 100",
    taxCut: "38 505",
  },
  {
    bruto: "96 200",
    taxCut: "38 559",
  },
  {
    bruto: "96 300",
    taxCut: "38 612",
  },
  {
    bruto: "96 400",
    taxCut: "38 666",
  },
  {
    bruto: "96 500",
    taxCut: "38 720",
  },
  {
    bruto: "96 600",
    taxCut: "38 773",
  },
  {
    bruto: "96 700",
    taxCut: "38 827",
  },
  {
    bruto: "96 800",
    taxCut: "38 880",
  },
  {
    bruto: "96 900",
    taxCut: "38 934",
  },
  {
    bruto: "97 000",
    taxCut: "38 987",
  },
  {
    bruto: "97 100",
    taxCut: "39 041",
  },
  {
    bruto: "97 200",
    taxCut: "39 095",
  },
  {
    bruto: "97 300",
    taxCut: "39 148",
  },
  {
    bruto: "97 400",
    taxCut: "39 202",
  },
  {
    bruto: "97 500",
    taxCut: "39 255",
  },
  {
    bruto: "97 600",
    taxCut: "39 309",
  },
  {
    bruto: "97 700",
    taxCut: "39 362",
  },
  {
    bruto: "97 800",
    taxCut: "39 416",
  },
  {
    bruto: "97 900",
    taxCut: "39 470",
  },
  {
    bruto: "98 000",
    taxCut: "39 523",
  },
  {
    bruto: "98 100",
    taxCut: "39 577",
  },
  {
    bruto: "98 200",
    taxCut: "39 630",
  },
  {
    bruto: "98 300",
    taxCut: "39 684",
  },
  {
    bruto: "98 400",
    taxCut: "39 737",
  },
  {
    bruto: "98 500",
    taxCut: "39 791",
  },
  {
    bruto: "98 600",
    taxCut: "39 844",
  },
  {
    bruto: "98 700",
    taxCut: "39 898",
  },
  {
    bruto: "98 800",
    taxCut: "39 952",
  },
  {
    bruto: "98 900",
    taxCut: "40 005",
  },
  {
    bruto: "99 000",
    taxCut: "40 059",
  },
  {
    bruto: "99 100",
    taxCut: "40 112",
  },
  {
    bruto: "99 200",
    taxCut: "40 166",
  },
  {
    bruto: "99 300",
    taxCut: "40 219",
  },
  {
    bruto: "99 400",
    taxCut: "40 273",
  },
  {
    bruto: "99 500",
    taxCut: "40 326",
  },
  {
    bruto: "99 600",
    taxCut: "40 380",
  },
  {
    bruto: "99 700",
    taxCut: "40 434",
  },
  {
    bruto: "99 800",
    taxCut: "40 487",
  },
];
