import { InputType } from "../common/Input/Input";
import { kommunaOptions } from "./kommunaOptions";

export interface WidgetInput {
  type: InputType;
  label: string;
  value?: number | string;
  options?: { value: number | string; label: string }[];
}

export const INPUTS: WidgetInput[] = [
  {
    type: "number",
    label: "Darbuotojo išdirbtos valandos",
    value: 0,
  },
  {
    type: "number",
    label: "Darbuotojo valandinis ant popieriaus",
    value: 0,
  },
  {
    type: "select",
    label: "Pasirinkite kommuna",
    value: undefined,
    options: kommunaOptions,
  },
  {
    type: "checkbox",
    label: "Standartinis",
    value: 1,
  },
  {
    type: "checkbox",
    label: "Procentai",
    value: 0,
  },
];

export const HOLIDAY_INPUTS: WidgetInput[] = [
  {
    type: "checkbox",
    label: "10.2 %",
    value: 1,
  },
  {
    type: "checkbox",
    label: "12 %",
    value: 0,
  },
];
