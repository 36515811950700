import React, { ChangeEvent, useEffect, useState } from "react";
import Input from "../../common/Input/Input";
import {
  HOLIDAY_INPUTS,
  INPUTS,
  WidgetInput,
} from "../../constants/widgetInputs";
import styles from "./CalculatorWidget.module.scss";
import { Option } from "../../common/Input/Input";
import {
  getEmployerPrice,
  getHolidayPrice,
  getPensionPrice,
  getEmployeeTotalWage,
  getTotalPrice,
  getEmployeeTax,
  getEmployeeWage,
  formatNumberWithThousandsSeparator,
} from "../../utils/priceHelper";
import "react-tooltip/dist/react-tooltip.css";
import { ReactSVG } from "react-svg";
import InfoCircle from "../../asset/info-circle.svg";
import { Tooltip } from "react-tooltip";

const CalculcatorWidget = (): JSX.Element => {
  const [inputData, setInputData] = useState<WidgetInput[]>(INPUTS);
  const [percentage, setPercentage] = useState<string | number | undefined>(0);
  const [holidayInputData, setHolidayInputData] =
    useState<WidgetInput[]>(HOLIDAY_INPUTS);

  const hours = inputData
    .find((input) => input.label === "Darbuotojo išdirbtos valandos")
    ?.value?.toString();

  const selectedKomuna = inputData.find(
    (input) => input.label === "Pasirinkite kommuna"
  )?.value;

  const rate = inputData
    .find((input) => input.label === "Darbuotojo valandinis ant popieriaus")
    ?.value?.toString();

  const holidayPlan = !!holidayInputData.find((input) => input.label === "12 %")
    ?.value;

  const isPercentagePlanSelected = !!inputData.find(
    (input) => input.label === "Procentai"
  )?.value;

  const isStandartPlanSelected = !!inputData.find(
    (input) => input.label === "Standartinis"
  )?.value;

  const onCheckboxChange = (label: string) => {
    if (label === "12 %" || label === "10.2 %") {
      return setHolidayInputData((prevState) => {
        const newInputs = prevState.map((prevInput) =>
          prevInput.label === label
            ? {
                ...prevInput,
                value: 1,
              }
            : prevInput.type === "checkbox"
            ? {
                ...prevInput,
                value: 0,
              }
            : {
                ...prevInput,
              }
        );

        return newInputs;
      });
    }

    return setInputData((prevState) => {
      const newInputs = prevState.map((prevInput) =>
        prevInput.label === label
          ? {
              ...prevInput,
              value: 1,
            }
          : prevInput.type === "checkbox"
          ? {
              ...prevInput,
              value: 0,
            }
          : {
              ...prevInput,
            }
      );

      return newInputs;
    });
  };

  const getValue = (
    value: string,
    percentage?: boolean
  ): string | number | null => {
    if (!value) {
      return 0;
    }
    const trimmedValue = value.trim().replace(/^0+/, "").replace(",", ".");
    const num = parseFloat(trimmedValue.replace(",", "."));

    if (isNaN(num) || num < 0) {
      return null;
    }

    const regexp = percentage
      ? /^(-?\d{0,2}(\.\d{0,2})?|\.\d{1,2})$/
      : /^(-?\d{0,5}(\.\d{0,2})?|\.\d{1,2})$/;

    if (!regexp.test(trimmedValue)) {
      return null;
    }

    return trimmedValue;
  };

  const onNumberChange = (event: ChangeEvent<any>, label: string) => {
    const { value } = event.target;

    if (label === "Mokesčių procentas") {
      return setPercentage((prevInput) => {
        return getValue(value, true) ?? prevInput;
      });
    }

    return setInputData((prevState) => {
      const newInputs = prevState.map((prevInput) =>
        prevInput.label === label
          ? {
              ...prevInput,
              value: getValue(value) ?? prevInput.value,
            }
          : { ...prevInput }
      );

      return newInputs;
    });
  };

  const onSelectChange = (option: Option, label: string) => {
    setInputData((prevState) => {
      const newInputs = prevState.map((prevInput) =>
        prevInput.label === label
          ? {
              ...prevInput,
              value: Number(option.value),
            }
          : { ...prevInput }
      );

      return newInputs;
    });
  };

  useEffect(() => {
    if (isStandartPlanSelected) {
      setInputData((prevState) => {
        const newInputs = prevState.map((prevInput) =>
          prevInput.label === "Pasirinkite kommuna"
            ? {
                ...prevInput,
                value: 0,
              }
            : { ...prevInput }
        );

        return newInputs;
      });

      setPercentage(0);
    }
  }, [isStandartPlanSelected]);

  useEffect(() => {
    if (isPercentagePlanSelected) {
      setPercentage(0);
    }
  }, [isPercentagePlanSelected]);

  const employeeTotalWage = getEmployeeTotalWage(hours, rate);

  const pensionPrice = getPensionPrice(employeeTotalWage);

  const holidayPrice = getHolidayPrice(holidayPlan, employeeTotalWage);

  const employeeTax = getEmployeeTax(
    employeeTotalWage,
    percentage ? percentage.toString() : 0
  );

  const employerPrice = getEmployerPrice(
    employeeTotalWage,
    pensionPrice,
    holidayPrice,
    selectedKomuna?.toString()
  );
  const employeeWage = getEmployeeWage(employeeTotalWage, employeeTax);

  const totalPrice = getTotalPrice(
    employeeTotalWage,
    holidayPrice,
    employerPrice,
    pensionPrice
  );

  return (
    <div className="ababa-tech-widget">
      <div className={styles.widgetWrapper}>
        <div className={styles.widgetRightColumn}>
          <div className={styles.widgetContainer}>
            <div className={styles.widgetBox}>
              {inputData
                .filter((input) => input.type !== "checkbox")
                .map((input, i) => {
                  return (
                    <Input
                      key={i}
                      type={input.type}
                      label={input.label}
                      value={input.value}
                      onNumberChange={onNumberChange}
                      onSelectChange={onSelectChange}
                      options={input.options}
                    />
                  );
                })}
            </div>
          </div>
        </div>
        <div className={styles.widgetLeftColumn}>
          <div className={styles.checkboxSection}>
            <h4>Nuskaičiuojami mokesčiai</h4>
            <div className={styles.checkboxSectionBox}>
              {inputData
                .filter((input) => input.type === "checkbox")
                .map((input, i) => {
                  return (
                    <Input
                      key={i}
                      type={input.type}
                      label={input.label}
                      value={input.value}
                      onCheckboxChange={onCheckboxChange}
                    />
                  );
                })}
            </div>
          </div>
          <div className={styles.checkboxSection}>
            <h4>Atostoginiai</h4>
            <div className={styles.checkboxSectionBox}>
              {holidayInputData
                .filter((input) => input.type === "checkbox")
                .map((input, i) => {
                  return (
                    <Input
                      key={i}
                      type={input.type}
                      label={input.label}
                      value={input.value}
                      onCheckboxChange={onCheckboxChange}
                      onSelectChange={onSelectChange}
                    />
                  );
                })}
            </div>
          </div>
          {isPercentagePlanSelected && (
            <div className={styles.percentagePlan}>
              <Input
                type="number"
                label="Mokesčių procentas"
                value={percentage}
                onNumberChange={onNumberChange}
              />
            </div>
          )}
        </div>
        <div className={styles.widgetBottomColumn}>
          <hr className={styles.widgetBottomDivider} />
          <div className={styles.calculationsWrapper}>
            <div className={styles.calculationsContainer}>
              <h4>Darbuotojo atlyginimas ant popieriaus</h4>
              <p>{formatNumberWithThousandsSeparator(employeeTotalWage)} kr.</p>
            </div>
            <div className={styles.calculationsContainer}>
              <h4>SKATT - darbuotojo mokesčiai nuo algos ant popieriaus</h4>
              <p>{formatNumberWithThousandsSeparator(employeeTax)} kr.</p>
            </div>
            <div className={styles.calculationsContainer}>
              <h4>Darbuotojo atlyginimas į rankas</h4>
              <p>{formatNumberWithThousandsSeparator(employeeWage)} kr.</p>
            </div>
            <div className={styles.calculationsContainer}>
              <h4>AGA darbdavio mokesčiai nuo algos ant popieriaus</h4>
              <p>{formatNumberWithThousandsSeparator(employerPrice)} kr.</p>
            </div>
            <div className={styles.calculationsContainer}>
              <h4>Atostoginiai</h4>
              <p>{formatNumberWithThousandsSeparator(holidayPrice)} kr.</p>
            </div>
            <div className={styles.calculationsContainer}>
              <h4>
                Pensijinis fondas (OTP)
                <span
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content={`2% yra minimali riba nuo algos arba
                prikaluso nuo pasirinkto pensijinio fondo imones`}
                  data-tooltip-place="top"
                >
                  <ReactSVG
                    src={InfoCircle}
                    className={styles.infoCircleIcon}
                  />
                </span>
                <Tooltip id="my-tooltip" noArrow />
              </h4>

              <p>{formatNumberWithThousandsSeparator(pensionPrice)} kr.</p>
            </div>
            <div className={styles.calculationsContainer}>
              <h4>Viso darbuotojas kainuoja</h4>
              <p>{formatNumberWithThousandsSeparator(totalPrice)} kr.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalculcatorWidget;
