import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import styles from "./Input.module.scss";
import CheckmarkIcon from "../../asset/checkmark.svg";

export type InputType = "number" | "checkbox" | "select";

export interface Option {
  value: string | number;
  label: string;
}

type Props = {
  type: InputType;
  label: string;
  value?: number | string;
  helperText?: string;
  options?: Option[];
  placeholder?: string;
  onCheckboxChange?: (label: string) => void;
  onNumberChange?: (event: ChangeEvent<any>, label: string) => void;
  onSelectChange?: (option: Option, label: string) => void;
};

const Input = ({
  type,
  label,
  value,
  onCheckboxChange,
  onNumberChange,
  onSelectChange,
  options,
  placeholder = "Select an option...",
}: Props) => {
  if (type === "checkbox" && onCheckboxChange) {
    return (
      <div className={styles.checkbox} onClick={() => onCheckboxChange(label)}>
        <div
          className={`${styles.checkboxIcon} ${value === 1 ? "checked" : ""}`}
        >
          {value === 1 && (
            <img
              src={CheckmarkIcon}
              alt="Checkmark icon"
              className={styles.checkmarkIcon}
            />
          )}
        </div>
        <div className={styles.checkboxLabel}>{label}</div>
      </div>
    );
  }

  if (type === "number" && onNumberChange) {
    return (
      <div className={styles.inputNumberWrapper}>
        <p>{label}</p>
        <div className={styles.inputNumberContainer}>
          <input
            type="text"
            onChange={(e) => onNumberChange(e, label)}
            value={value}
          ></input>
        </div>
      </div>
    );
  }

  if (type === "select" && options && onSelectChange) {
    const [selectedOption, setSelectedOption] = useState<Option | null>(null);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [showOptions, setShowOptions] = useState(false);
    const selectRef = useRef<HTMLDivElement>(null);

    const handleInputFocus = () => {
      setShowOptions(true);
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setShowOptions(false);
      }
    };

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    const handleOptionClick = (option: Option, label: string) => {
      setSelectedOption(option);
      setSearchTerm("");
      onSelectChange?.(option, label);
      setShowOptions(false);
    };

    const filteredOptions = options.filter((option) =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleInput = (event: React.FormEvent<HTMLDivElement>) => {
      const input = event.currentTarget.innerText.trim();
      if (input === "") {
        setSelectedOption(null);
      }
    };

    return (
      <div className={styles["select-container-wrapper"]}>
        <p>{label}</p>
        <div className={styles["select-container"]} ref={selectRef}>
          <input
            type="text"
            className={styles["select-input"]}
            placeholder={placeholder}
            value={selectedOption?.label || searchTerm}
            contentEditable={true}
            onInput={handleInput}
            onChange={(e) => setSearchTerm(e.target.value)}
            onFocus={handleInputFocus}
          />
          {showOptions && (
            <ul className={styles["select-options"]}>
              {filteredOptions.map((option, i) => (
                <li
                  key={`${option.value}${i}`}
                  className={`${styles["option"]} ${
                    option === selectedOption ? styles["selected"] : ""
                  }`}
                  onClick={() => handleOptionClick(option, label)}
                >
                  {option.label}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    );
  }

  return <></>;
};

export default Input;
