import { tabell } from "../constants/tabell";

export const getEmployeeTotalWage = (hours?: string, rate?: string) => {
  if (!hours || !rate) {
    return 0;
  }

  const prasedHours = parseFloat(hours);
  const prasedRate = parseFloat(rate);

  return (prasedHours * prasedRate).toFixed(2);
};

export const getEmployeeTax = (
  employeeTotalWage?: string | 0,
  percentage?: string | 0
) => {
  if (!employeeTotalWage) {
    return 0;
  }

  if (percentage) {
    const deduction = (
      (parseFloat(employeeTotalWage) * parseFloat(percentage)) /
      100
    ).toFixed(2);

    return parseFloat(deduction).toFixed(2);
  }

  const tax = getTaxByPrice(parseFloat(employeeTotalWage));

  const deduction = (parseFloat(employeeTotalWage) - tax).toFixed(2);

  return (parseFloat(employeeTotalWage) - parseFloat(deduction)).toFixed(2);
};

export const getPensionPrice = (totalPrice?: string | 0) => {
  if (!totalPrice) {
    return 0;
  }

  return (parseFloat(totalPrice) * 0.02).toFixed(2);
};

export const getEmployerPrice = (
  priceOnPaper?: string | 0,
  pensionPrice?: string | 0,
  holidayPrice?: string | 0,
  kommunaPercentage?: string | 0
) => {
  if (!priceOnPaper || !pensionPrice || !kommunaPercentage || !holidayPrice) {
    return 0;
  }

  const onPaperAndPension =
    parseFloat(priceOnPaper) +
    parseFloat(pensionPrice) +
    parseFloat(holidayPrice);

  const result = parseFloat(kommunaPercentage) * onPaperAndPension;

  return result.toFixed(2);
};

export const getHolidayPrice = (
  isRatioExtended: boolean,
  totalPrice?: string | 0
) => {
  if (!totalPrice) {
    return 0;
  }

  if (isRatioExtended) {
    return ((parseFloat(totalPrice) / 100) * 12).toFixed(2);
  }

  return ((parseFloat(totalPrice) / 100) * 10.2).toFixed(2);
};

export const getTotalPrice = (
  employerPrice?: string | 0,
  holidayPrice?: string | 0,
  employeeTax?: string | 0,
  pensionPrice?: string | 0
) => {
  if (!employerPrice || !holidayPrice || !employeeTax || !pensionPrice) {
    return 0;
  }

  return (
    parseFloat(employerPrice) +
    parseFloat(holidayPrice) +
    parseFloat(employeeTax) +
    parseFloat(pensionPrice)
  ).toFixed(2);
};

const getTaxByPrice = (price: number): number => {
  const priceTaxObj = tabell.find(
    (pt) => price <= parseInt(pt.bruto.replace(/\D/g, ""))
  );

  if (priceTaxObj) {
    return parseInt(priceTaxObj.taxCut.toString().replace(/\D/g, ""));
  }

  return 0;
};

export const getEmployeeWage = (
  employeeTotalWage?: string | 0,
  employeeTax?: string | 0
) => {
  if (!employeeTotalWage || !employeeTax) {
    return 0;
  }

  return (parseFloat(employeeTotalWage) - parseFloat(employeeTax)).toFixed(2);
};

export const formatNumberWithThousandsSeparator = (
  stringNumber: string | 0
) => {
  const number = stringNumber === 0 ? "0" : stringNumber;

  const parts = parseFloat(number).toFixed(2).toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
};
