export const kommunaOptions = [
  {
    label: "Ålesund",
    value: 0.141,
  },
  {
    label: "Alver",
    value: 0.141,
  },
  {
    label: "Aremark",
    value: 0.141,
  },
  {
    label: "Arendal",
    value: 0.141,
  },
  {
    label: "Ås",
    value: 0.141,
  },
  {
    label: "Asker",
    value: 0.141,
  },
  {
    label: "Askøy",
    value: 0.141,
  },
  {
    label: "Aukra",
    value: 0.141,
  },
  {
    label: "Aurskog-Høland",
    value: 0.141,
  },
  {
    label: "Austevoll",
    value: 0.141,
  },
  {
    label: "Austrheim",
    value: 0.141,
  },
  {
    label: "Averøy",
    value: 0.141,
  },
  {
    label: "Bærum",
    value: 0.141,
  },
  {
    label: "Bamble",
    value: 0.141,
  },
  {
    label: "Bergen",
    value: 0.141,
  },
  {
    label: "Birkenes",
    value: 0.141,
  },
  {
    label: "Bjerkreim",
    value: 0.141,
  },
  {
    label: "Bjørnafjorden",
    value: 0.141,
  },
  {
    label: "Bokn",
    value: 0.141,
  },
  {
    label: "Bømlo",
    value: 0.141,
  },
  {
    label: "Drammen",
    value: 0.141,
  },
  {
    label: "Eidsvoll",
    value: 0.141,
  },
  {
    label: "Eigersund",
    value: 0.141,
  },
  {
    label: "Elverum",
    value: 0.141,
  },
  {
    label: "Enebakk",
    value: 0.141,
  },
  {
    label: "Etne",
    value: 0.141,
  },
  {
    label: "Færder",
    value: 0.141,
  },
  {
    label: "Farsund",
    value: 0.141,
  },
  {
    label: "Fitjar",
    value: 0.141,
  },
  {
    label: "Flekkefjord",
    value: 0.141,
  },
  {
    label: "Flesberg",
    value: 0.141,
  },
  {
    label: "Fredrikstad",
    value: 0.141,
  },
  {
    label: "Frogn",
    value: 0.141,
  },
  {
    label: "Froland",
    value: 0.141,
  },
  {
    label: "Frosta",
    value: 0.141,
  },
  {
    label: "Gausdal",
    value: 0.141,
  },
  {
    label: "Giske",
    value: 0.141,
  },
  {
    label: "Gjemnes",
    value: 0.141,
  },
  {
    label: "Gjerdrum",
    value: 0.141,
  },
  {
    label: "Gjesdal",
    value: 0.141,
  },
  {
    label: "Gjøvik",
    value: 0.141,
  },
  {
    label: "Gran",
    value: 0.141,
  },
  {
    label: "Grimstad",
    value: 0.141,
  },
  {
    label: "Hå",
    value: 0.141,
  },
  {
    label: "Hægebostad",
    value: 0.141,
  },
  {
    label: "Halden",
    value: 0.141,
  },
  {
    label: "Hamar",
    value: 0.141,
  },
  {
    label: "Hareid",
    value: 0.141,
  },
  {
    label: "Haugesund",
    value: 0.141,
  },
  {
    label: "Hole",
    value: 0.141,
  },
  {
    label: "Holmestrand",
    value: 0.141,
  },
  {
    label: "Horten",
    value: 0.141,
  },
  {
    label: "Hurdal",
    value: 0.141,
  },
  {
    label: "Hustadvika",
    value: 0.141,
  },
  {
    label: "Hvaler",
    value: 0.141,
  },
  {
    label: "Indre-Østfold",
    value: 0.141,
  },
  {
    label: "Iveland",
    value: 0.141,
  },
  {
    label: "Jevnaker",
    value: 0.141,
  },
  {
    label: "Karmøy",
    value: 0.141,
  },
  {
    label: "Kinn",
    value: 0.141,
  },
  {
    label: "Klepp",
    value: 0.141,
  },
  {
    label: "Kongsberg",
    value: 0.141,
  },
  {
    label: "Kragerø",
    value: 0.141,
  },
  {
    label: "Kristiansund",
    value: 0.141,
  },
  {
    label: "Krødsherad",
    value: 0.141,
  },
  {
    label: "Kvam",
    value: 0.141,
  },
  {
    label: "Kvinesdal",
    value: 0.141,
  },
  {
    label: "Kvitsøy",
    value: 0.141,
  },
  {
    label: "Larvik",
    value: 0.141,
  },
  {
    label: "Levanger",
    value: 0.141,
  },
  {
    label: "Lier",
    value: 0.141,
  },
  {
    label: "Lillehammer",
    value: 0.141,
  },
  {
    label: "Lillesand",
    value: 0.141,
  },
  {
    label: "Lillestrøm",
    value: 0.141,
  },
  {
    label: "Lindesnes",
    value: 0.141,
  },
  {
    label: "Lørenskog",
    value: 0.141,
  },
  {
    label: "Løten",
    value: 0.141,
  },
  {
    label: "Lund",
    value: 0.141,
  },
  {
    label: "Lunner",
    value: 0.141,
  },
  {
    label: "Lyngdal",
    value: 0.141,
  },
  {
    label: "Malvik",
    value: 0.141,
  },
  {
    label: "Marker",
    value: 0.141,
  },
  {
    label: "Melhus",
    value: 0.141,
  },
  {
    label: "Midtre Gauldal",
    value: 0.141,
  },
  {
    label: "Midt-Telemark",
    value: 0.141,
  },
  {
    label: "Modum",
    value: 0.141,
  },
  {
    label: "Molde",
    value: 0.141,
  },
  {
    label: "Moss",
    value: 0.141,
  },
  {
    label: "Nannestad",
    value: 0.141,
  },
  {
    label: "Nes",
    value: 0.141,
  },
  {
    label: "Nesodden",
    value: 0.141,
  },
  {
    label: "Nittedal",
    value: 0.141,
  },
  {
    label: "Nordre Follo",
    value: 0.141,
  },
  {
    label: "Notodden",
    value: 0.141,
  },
  {
    label: "Orkland",
    value: 0.141,
  },
  {
    label: "Ørsta",
    value: 0.141,
  },
  {
    label: "Oslo",
    value: 0.141,
  },
  {
    label: "Osterøy",
    value: 0.141,
  },
  {
    label: "Østre Toten",
    value: 0.141,
  },
  {
    label: "Øvre Eiker",
    value: 0.141,
  },
  {
    label: "Øyer",
    value: 0.141,
  },
  {
    label: "Øygarden",
    value: 0.141,
  },
  {
    label: "Porsgrunn",
    value: 0.141,
  },
  {
    label: "Råde",
    value: 0.141,
  },
  {
    label: "Rælingen",
    value: 0.141,
  },
  {
    label: "Rakkestad",
    value: 0.141,
  },
  {
    label: "Randaberg",
    value: 0.141,
  },
  {
    label: "Ringerike",
    value: 0.141,
  },
  {
    label: "Samnanger",
    value: 0.141,
  },
  {
    label: "Sandefjord",
    value: 0.141,
  },
  {
    label: "Sandnes",
    value: 0.141,
  },
  {
    label: "Sarpsborg",
    value: 0.141,
  },
  {
    label: "Selbu",
    value: 0.141,
  },
  {
    label: "Sigdal",
    value: 0.141,
  },
  {
    label: "Siljan",
    value: 0.141,
  },
  {
    label: "Sirdal",
    value: 0.141,
  },
  {
    label: "Skaun",
    value: 0.141,
  },
  {
    label: "Skien",
    value: 0.141,
  },
  {
    label: "Skiptvet",
    value: 0.141,
  },
  {
    label: "Sogndal",
    value: 0.141,
  },
  {
    label: "Sokndal",
    value: 0.141,
  },
  {
    label: "Sola",
    value: 0.141,
  },
  {
    label: "Stange",
    value: 0.141,
  },
  {
    label: "Stavanger",
    value: 0.141,
  },
  {
    label: "Steinkjer",
    value: 0.141,
  },
  {
    label: "Stjørdal",
    value: 0.141,
  },
  {
    label: "Stord",
    value: 0.141,
  },
  {
    label: "Strand",
    value: 0.141,
  },
  {
    label: "Sula",
    value: 0.141,
  },
  {
    label: "Sveio",
    value: 0.141,
  },
  {
    label: "Time",
    value: 0.141,
  },
  {
    label: "Tønsberg",
    value: 0.141,
  },
  {
    label: "Trondheim",
    value: 0.141,
  },
  {
    label: "Trondheim",
    value: 0.141,
  },
  {
    label: "Tvedestrand",
    value: 0.141,
  },
  {
    label: "Tysvær",
    value: 0.141,
  },
  {
    label: "Ullensaker",
    value: 0.141,
  },
  {
    label: "Ulstein",
    value: 0.141,
  },
  {
    label: "Utsira",
    value: 0.141,
  },
  {
    label: "Vaksdal",
    value: 0.141,
  },
  {
    label: "Våler",
    value: 0.141,
  },
  {
    label: "Vegårshei",
    value: 0.141,
  },
  {
    label: "Vennesla",
    value: 0.141,
  },
  {
    label: "Verdal",
    value: 0.141,
  },
  {
    label: "Vestby",
    value: 0.141,
  },
  {
    label: "Vestre Toten",
    value: 0.141,
  },
  {
    label: "Vindafjord",
    value: 0.141,
  },
  {
    label: "Volda",
    value: 0.141,
  },
  {
    label: "Voss",
    value: 0.141,
  },
  {
    label: "Ål",
    value: 0.106,
  },
  {
    label: "Åmli",
    value: 0.106,
  },
  {
    label: "Åmot",
    value: 0.106,
  },
  {
    label: "Årdal",
    value: 0.106,
  },
  {
    label: "Åseral",
    value: 0.106,
  },
  {
    label: "Askvoll",
    value: 0.106,
  },
  {
    label: "Åsnes",
    value: 0.106,
  },
  {
    label: "Aurland",
    value: 0.106,
  },
  {
    label: "Bremanger",
    value: 0.106,
  },
  {
    label: "Bygland",
    value: 0.106,
  },
  {
    label: "Bykle",
    value: 0.106,
  },
  {
    label: "Drangedal",
    value: 0.106,
  },
  {
    label: "Eidfjord",
    value: 0.106,
  },
  {
    label: "Eidskog",
    value: 0.106,
  },
  {
    label: "Evje og Hornnes",
    value: 0.106,
  },
  {
    label: "Fedje",
    value: 0.106,
  },
  {
    label: "Fjaler",
    value: 0.106,
  },
  {
    label: "Fjord",
    value: 0.106,
  },
  {
    label: "Flå",
    value: 0.106,
  },
  {
    label: "Fyresdal",
    value: 0.106,
  },
  {
    label: "Gjerstad",
    value: 0.106,
  },
  {
    label: "Gloppen",
    value: 0.106,
  },
  {
    label: "Gol",
    value: 0.106,
  },
  {
    label: "Grue",
    value: 0.106,
  },
  {
    label: "Gulen",
    value: 0.106,
  },
  {
    label: "Hemsedal",
    value: 0.106,
  },
  {
    label: "Hjartdal",
    value: 0.106,
  },
  {
    label: "Hjelmeland",
    value: 0.106,
  },
  {
    label: "Hol",
    value: 0.106,
  },
  {
    label: "Høyanger",
    value: 0.106,
  },
  {
    label: "Hyllestad",
    value: 0.106,
  },
  {
    label: "Inderøy",
    value: 0.106,
  },
  {
    label: "Indre Fosen",
    value: 0.106,
  },
  {
    label: "Kongsvinger",
    value: 0.106,
  },
  {
    label: "Kvinnherad",
    value: 0.106,
  },
  {
    label: "Kviteseid",
    value: 0.106,
  },
  {
    label: "Lærdal",
    value: 0.106,
  },
  {
    label: "Luster",
    value: 0.106,
  },
  {
    label: "Masfjorden",
    value: 0.106,
  },
  {
    label: "Meråker",
    value: 0.106,
  },
  {
    label: "Modalen",
    value: 0.106,
  },
  {
    label: "Molde",
    value: 0.106,
  },
  {
    label: "Nesbyen",
    value: 0.106,
  },
  {
    label: "Nissedal",
    value: 0.106,
  },
  {
    label: "Nome",
    value: 0.106,
  },
  {
    label: "Nord-Fron",
    value: 0.106,
  },
  {
    label: "Nord-Odal",
    value: 0.106,
  },
  {
    label: "Nordre Land",
    value: 0.106,
  },
  {
    label: "Nore og Uvdal",
    value: 0.106,
  },
  {
    label: "Ørland",
    value: 0.106,
  },
  {
    label: "Rauma",
    value: 0.106,
  },
  {
    label: "Ringebu",
    value: 0.106,
  },
  {
    label: "Risør",
    value: 0.106,
  },
  {
    label: "Rollag",
    value: 0.106,
  },
  {
    label: "Sande",
    value: 0.106,
  },
  {
    label: "Sauda",
    value: 0.106,
  },
  {
    label: "Seljord",
    value: 0.106,
  },
  {
    label: "Solund",
    value: 0.106,
  },
  {
    label: "Søndre Land",
    value: 0.106,
  },
  {
    label: "Sør-Fron",
    value: 0.106,
  },
  {
    label: "Sør-Odal",
    value: 0.106,
  },
  {
    label: "Stad",
    value: 0.106,
  },
  {
    label: "Stad",
    value: 0.106,
  },
  {
    label: "Steinkjer",
    value: 0.106,
  },
  {
    label: "Stranda",
    value: 0.106,
  },
  {
    label: "Stryn",
    value: 0.106,
  },
  {
    label: "Suldal",
    value: 0.106,
  },
  {
    label: "Sunndal",
    value: 0.106,
  },
  {
    label: "Sunnfjord",
    value: 0.106,
  },
  {
    label: "Sykkylven",
    value: 0.106,
  },
  {
    label: "Tingvoll",
    value: 0.106,
  },
  {
    label: "Tinn",
    value: 0.106,
  },
  {
    label: "Tokke",
    value: 0.106,
  },
  {
    label: "Trysil",
    value: 0.106,
  },
  {
    label: "Tysnes",
    value: 0.106,
  },
  {
    label: "Ullensvang",
    value: 0.106,
  },
  {
    label: "Ulvik",
    value: 0.106,
  },
  {
    label: "Valle",
    value: 0.106,
  },
  {
    label: "Vestnes",
    value: 0.106,
  },
  {
    label: "Vik",
    value: 0.106,
  },
  {
    label: "Vinje",
    value: 0.106,
  },
  {
    label: "Alvdal",
    value: 0.064,
  },
  {
    label: "Aure",
    value: 0.064,
  },
  {
    label: "Dovre",
    value: 0.064,
  },
  {
    label: "Engerdal",
    value: 0.064,
  },
  {
    label: "Etnedal",
    value: 0.064,
  },
  {
    label: "Folldal",
    value: 0.064,
  },
  {
    label: "Frøya",
    value: 0.064,
  },
  {
    label: "Heim",
    value: 0.064,
  },
  {
    label: "Hitra",
    value: 0.064,
  },
  {
    label: "Hitra",
    value: 0.064,
  },
  {
    label: "Holtålen",
    value: 0.064,
  },
  {
    label: "Lesja",
    value: 0.064,
  },
  {
    label: "Lom",
    value: 0.064,
  },
  {
    label: "Nord-Aurdal",
    value: 0.064,
  },
  {
    label: "Oppdal",
    value: 0.064,
  },
  {
    label: "Os",
    value: 0.064,
  },
  {
    label: "Øystre Slidre",
    value: 0.064,
  },
  {
    label: "Rendalen",
    value: 0.064,
  },
  {
    label: "Rennebu",
    value: 0.064,
  },
  {
    label: "Rindal",
    value: 0.064,
  },
  {
    label: "Røros",
    value: 0.064,
  },
  {
    label: "Sel",
    value: 0.064,
  },
  {
    label: "Skjåk",
    value: 0.064,
  },
  {
    label: "Sør-Aurdal",
    value: 0.064,
  },
  {
    label: "Stor-Elvdal",
    value: 0.064,
  },
  {
    label: "Surnadal",
    value: 0.064,
  },
  {
    label: "Tolga",
    value: 0.064,
  },
  {
    label: "Tydal",
    value: 0.064,
  },
  {
    label: "Tynset",
    value: 0.064,
  },
  {
    label: "Vågå",
    value: 0.064,
  },
  {
    label: "Vang",
    value: 0.064,
  },
  {
    label: "Vanylven",
    value: 0.064,
  },
  {
    label: "Vestre Slidre",
    value: 0.064,
  },
  {
    label: "Åfjord",
    value: 0.051,
  },
  {
    label: "Alstahaug",
    value: 0.051,
  },
  {
    label: "Andøy",
    value: 0.051,
  },
  {
    label: "Balsfjord",
    value: 0.051,
  },
  {
    label: "Bardu",
    value: 0.051,
  },
  {
    label: "Beiarn",
    value: 0.051,
  },
  {
    label: "Bindal",
    value: 0.051,
  },
  {
    label: "Bø",
    value: 0.051,
  },
  {
    label: "Brønnøy",
    value: 0.051,
  },
  {
    label: "Dielddanuorri - Tjeldsund",
    value: 0.051,
  },
  {
    label: "Dønna",
    value: 0.051,
  },
  {
    label: "Dyrøy",
    value: 0.051,
  },
  {
    label: "Evenášši - Evenes",
    value: 0.051,
  },
  {
    label: "Flakstad",
    value: 0.051,
  },
  {
    label: "Flatanger",
    value: 0.051,
  },
  {
    label: "Fuossko - Fauske",
    value: 0.051,
  },
  {
    label: "Gildeskål",
    value: 0.051,
  },
  {
    label: "Grane",
    value: 0.051,
  },
  {
    label: "Gratangen",
    value: 0.051,
  },
  {
    label: "Grong",
    value: 0.051,
  },
  {
    label: "Hábmer - Hamarøy",
    value: 0.051,
  },
  {
    label: "Hadsel",
    value: 0.051,
  },
  {
    label: "Hárstták - Harstad",
    value: 0.051,
  },
  {
    label: "Hattfjelldal",
    value: 0.051,
  },
  {
    label: "Hemnes",
    value: 0.051,
  },
  {
    label: "Herøy",
    value: 0.051,
  },
  {
    label: "Høylandet",
    value: 0.051,
  },
  {
    label: "Ibestad",
    value: 0.051,
  },
  {
    label: "Kvæfjord",
    value: 0.051,
  },
  {
    label: "Leirfjord",
    value: 0.051,
  },
  {
    label: "Leka",
    value: 0.051,
  },
  {
    label: "Lierne",
    value: 0.051,
  },
  {
    label: "Loabák - Lavangen",
    value: 0.051,
  },
  {
    label: "Lødingen",
    value: 0.051,
  },
  {
    label: "Lurøy",
    value: 0.051,
  },
  {
    label: "Målselv",
    value: 0.051,
  },
  {
    label: "Meløy",
    value: 0.051,
  },
  {
    label: "Moskenes",
    value: 0.051,
  },
  {
    label: "Nærøysund",
    value: 0.051,
  },
  {
    label: "Namsos - Nåavmesjenjaelmie",
    value: 0.051,
  },
  {
    label: "Namsskogan",
    value: 0.051,
  },
  {
    label: "Narvik",
    value: 0.051,
  },
  {
    label: "Nesna",
    value: 0.051,
  },
  {
    label: "Øksnes",
    value: 0.051,
  },
  {
    label: "Osen",
    value: 0.051,
  },
  {
    label: "Overhalla",
    value: 0.051,
  },
  {
    label: "Raarvihke - Røyrvik",
    value: 0.051,
  },
  {
    label: "Rana",
    value: 0.051,
  },
  {
    label: "Rødøy",
    value: 0.051,
  },
  {
    label: "Røst",
    value: 0.051,
  },
  {
    label: "Salangen",
    value: 0.051,
  },
  {
    label: "Saltdal",
    value: 0.051,
  },
  {
    label: "Senja",
    value: 0.051,
  },
  {
    label: "Smøla",
    value: 0.051,
  },
  {
    label: "Snåase - Snåsa",
    value: 0.051,
  },
  {
    label: "Sømna",
    value: 0.051,
  },
  {
    label: "Sørfold",
    value: 0.051,
  },
  {
    label: "Sørreisa",
    value: 0.051,
  },
  {
    label: "Steigen",
    value: 0.051,
  },
  {
    label: "Suortá - Sortland",
    value: 0.051,
  },
  {
    label: "Træna",
    value: 0.051,
  },
  {
    label: "Værøy",
    value: 0.051,
  },
  {
    label: "Vågan",
    value: 0.051,
  },
  {
    label: "Vefsn",
    value: 0.051,
  },
  {
    label: "Vega",
    value: 0.051,
  },
  {
    label: "Vestvågøy",
    value: 0.051,
  },
  {
    label: "Vevelstad",
    value: 0.051,
  },
  {
    label: "Alta",
    value: 0,
  },
  {
    label: "Båtsfjord",
    value: 0,
  },
  {
    label: "Berlevåg",
    value: 0,
  },
  {
    label: "Deatnu - Tana",
    value: 0,
  },
  {
    label: "Gáivuotna - Kaivuono - Kåfjord",
    value: 0,
  },
  {
    label: "Gamvik",
    value: 0,
  },
  {
    label: "Guovdageaidnu - Kautokeino",
    value: 0,
  },
  {
    label: "Hámmerfeasta - Hammerfest",
    value: 0,
  },
  {
    label: "Hasvik",
    value: 0,
  },
  {
    label: "Kárášjohka - Karasjok",
    value: 0,
  },
  {
    label: "Karlsøy",
    value: 0,
  },
  {
    label: "Kvænangen",
    value: 0,
  },
  {
    label: "Lebesby",
    value: 0,
  },
  {
    label: "Loppa",
    value: 0,
  },
  {
    label: "Lyngen",
    value: 0,
  },
  {
    label: "Måsøy",
    value: 0,
  },
  {
    label: "Nordkapp",
    value: 0,
  },
  {
    label: "Omasvuotna - Omasvuono - Storfjord",
    value: 0,
  },
  {
    label: "Porsángu - Porsanki - Porsanger",
    value: 0,
  },
  {
    label: "Ráisa - Raisi - Nordreisa",
    value: 0,
  },
  {
    label: "Skjervøy",
    value: 0,
  },
  {
    label: "Sør-Varanger",
    value: 0,
  },
  {
    label: "Unjárga - Nesseby",
    value: 0,
  },
  {
    label: "Vadsø",
    value: 0,
  },
  {
    label: "Vardø",
    value: 0,
  },
  {
    label: "Bodø",
    value: 0.079,
  },
  {
    label: "Tromsø",
    value: 0.079,
  },
];
